import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Input, Spin } from "antd";
import download from "../../../assets/svg/file_download-24px.svg";
import upload from "../../../assets/svg/file_upload-24px.svg";
import toast from "react-hot-toast";
import MembersExcelModal from "./modal/MembersExcelModal";
import {
  downloadMembersExcel,
  fetchGroupMembers,
} from "../../../features/fetch/fetchSlice";
import BreadCrumb from "../../../layout/BreadCrumb";
import { capitalize } from "../../../utils";

const url = process.env.REACT_APP_API_BASE_URL;

export default function MembersUploadList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const { activeObj } = useSelector((state) => state.obj);
  const { user } = useSelector((state) => state.auth);
  
  const { loading } = useSelector((state) => state.fetch);

  const [fileUrl, setfileUrl] = useState();
 

  const [downloaded, setDownloaded] = useState(false);

  const handleClick = async (event) => {
    event.preventDefault();
    const res = await dispatch(downloadMembersExcel());

    if (res?.payload) {
      const blob = new Blob([res.payload], {
        type: "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);

     await toast.success("File downloaded successfully");
     await setDownloaded(true)
    } else {
      toast.error("Failed to download file");
    }
  };
  const [openExcelUpload, setopenExcelUpload] = useState(false);
  function handleCancelExcelUpload() {
    setopenExcelUpload(false);
  }

  async function handleCancel() {
    await setfileUrl();
  }

  const handleSuccess = (response) => {
    setfileUrl(response);
  };

  const handleError = (response) => {
    // Handle error logic
    console.error("File upload failed:", response);
  };

  function handleGoBack() {
    navigate(-1);
  }

  async function handleFetchData() {
    await dispatch(
      fetchGroupMembers({
        url: "api/v1/payments/fetch-payment-group-details",
        pgdPgId: activeObj?.pgId,
      })
    );
  }

  function handleBackToList() {
    navigate(`/groups/folder/${activeObj?.pgName}`);
  }

  const onFinish = async (values) => {
    // setloading(true);
    // // values.gdcInstId = user?.usrInstId;
    // // values.gdcCreatedBy = user?.usrId;
    // // values.gdcFldId = folderObj?.fldId;
    // let formData = new FormData();
    // let success;
    // formData.append("file", fileUrl);
    // if (!folderObj?.fldId) {
    //   toast.error("A folder must be selected before uploading a list");
    //   await navigate(`/contacts`);
    // }
    // if (!values.gdcFileUrl) {
    //   setloading(false);
    //   toast.error("Please select a file before submitting");
    // }
    // await fetch(
    //   `${url}/emp/uploadGraduateExcel.action?gdcInstId=${
    //     user?.usrInstId
    //   }&gdcCreatedBy=
    // 	${user?.usrId}&gdcFldId=${folderObj?.fldId}&gdcTitle=${values.gdcTitle}&gdcId=${
    //     gradUpload ? gradListObj?.gdcId : null
    //   }`,
    //   {
    //     method: "POST",
    //     body: formData,
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     success = data.success;
    //     if (data?.success) {
    //       toast.success("List of contacts uploaded");
    //       form.resetFields();
    //       setloading(false);
    //     } else {
    //       toast.error(data?.messages?.message);
    //       setloading(false);
    //     }
    //   });
    // if (success) {
    //   await handleFetchData();
    //   await handleBackToList();
    // }
  };

  const breadList = [
    {
      title: (
        <div
          className="flex items-center gap-[.25rem] cursor-pointer"
          onClick={handleBackToList}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>{activeObj?.pgName}</span>
        </div>
      ),
    },
    {
      title: capitalize("Group Members Excel Upload"),
    },
  ];

  return (
    <div className="w-full overflow-y-scroll">
      <div className="justify-between items-center white_card">
        <BreadCrumb
          breadList={breadList}
          header={"Members Bulk Upload"}
          info={"Add group members in bulk"}
        />
      </div>

      <div className="lg:px-10 px-3 mt-[51px]">
        <span className="text-black1  text-[24px] font-bold dash-title leading-[32.4px]">
          Import list of members (excel file)
        </span>

        <div
          className="mt-[31px] bg-white flex flex-col justify-center items-center border bottom-solid border-[#F5F7FF]
		    lg:w-[912px] w-full lg:h-[306px] h-full px-0 lg:py-[60px] pt-5 pb-10"
        >
          {downloaded ? (
            <>
              <div className="text-black1 text-[18px] font-normal font-dmSans leading-[24px]">
                You have downloaded the template
              </div>
              <div className="mt-[10px] text-[#616161] text-[16px] font-normal leading-[24px]">
                Fill in the template with the list of members and upload it
                when you are done
              </div>
              <div className="flex flex-col mt-[20px]">
                <a
                  className="cursor-pointer w-[250px] h-[50px] px-[24px] py-3 bg-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
				text-white text-[16px] font-medium "
                  download
                  onClick={() => setopenExcelUpload(true)}
                >
                  <img src={upload} alt="upload-icon" />
                  Upload template
                </a>
                <div
                  className="w-[300px]"
                  onClick={handleClick}
                 
                >
                  <span className="flex items-center cstm-btn-2 cursor-pointer"> 
                   {loading ? <Spin/> : <> <img src={download} alt="download-icon" />Re-download template </>} 
                  </span>
                </div>
               
                <div
                  className="mt-[10px] w-full h-[50px] px-[24px] py-3 border-none text-darkBlue rounded-[28px] justify-center items-center gap-1 inline-flex
				              text-[16px] font-medium cursor-pointer text-darkGreen"
                  onClick={() => setopenExcelUpload(true)}
                >
                  Already have template ? Proceed to upload
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="text-black1 text-[18px] font-normal font-dmSans leading-[24px]">
                Complete the following steps to import your list of contacts
              </div>

              <div className="mt-[10px] text-[#616161] text-[16px] font-normal leading-[24px]">
                Step 1. Download the excel template by clicking the button
                below.
                <br />
                Step 2. Fill in the template with the list of contacts.
                <br />
                Step 3. Upload the filled in template.
              </div>

              <div className="flex flex-col mt-[20px] justify-center items-center">
                <div
                  className="w-[250px] "
                  onClick={handleClick}
                >
                  <span className="flex items-center cstm-btn-2 cursor-pointer">
                   
                    {loading ? <Spin/> : (
                      <> <img src={download} alt="download-icon" />Download template</>
                    )  } 
                  </span>
                </div>

                <div
                  className="mt-[10px] w-full h-[50px] px-[24px] py-3 border-none text-darkBlue rounded-[28px] justify-center items-center gap-1 inline-flex
				              text-[16px] font-medium cursor-pointer text-darkGreen"
                  onClick={() => setopenExcelUpload(true)}
                >
                  Already have template ? Proceed to upload
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <MembersExcelModal
        open={openExcelUpload}
        handleCancelModal={handleCancelExcelUpload}
      />
    </div>
  );
}
