import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Form, Input, Spin } from 'antd';
import { handleLoginSession, handleUserOut, handleUserRefreshToken, login, logout } from '../../features/auth/authSlice';
import { useEffect, useRef } from 'react';
import { customToast } from '../../utils';
import img2 from '../../assets/img/img2.png';
import img3 from '../../assets/img/img3.png';
import Header from './Header';
import { cleanOrgDocObj, cleanOrgObj } from '../../features/obj/objSlice';
import toast from 'react-hot-toast';
import { fetchMenu } from '../../features/global/globalSlice';
import { jwtDecode } from 'jwt-decode';

export default function LoginOtpCode() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authLoading, loginInfo } = useSelector((state) => state.auth);
    const formRef = useRef(null);
    const onFinish = async (data) => {
        const requestData = {
            ...data,
            usrOrgCode: loginInfo?.usrOrgCode,
            userEmail: loginInfo?.userEmail,
            password: loginInfo?.password,
        };
        const res = await dispatch(login(requestData)); 
        if (res?.payload?.success) { 
            console.log("(res?.payload?.data?.result?.orgStatus",res?.payload?.data?.result?.orgStatus)
            await toast.success(res?.payload?.messages?.message);
            if (res?.payload?.data?.result?.orgStatus === "PENDING" || res?.payload?.data?.result?.orgStatus === "REJECTED" || res?.payload?.data?.result?.orgStatus === "PENDING_APPROVAL") {
               toast.error("Pending Organization Approval")
            }  else if (res?.payload?.data?.result?.orgStatus === "APPROVED" || res?.payload?.data?.result?.orgStatus === "REJECTED"){ 
                await localStorage.setItem('token', res?.payload?.data?.result?.accessToken);
                await dispatch(handleUserRefreshToken(res?.payload?.data?.result?.refreshToken));
                await dispatch(handleLoginSession(true));
                await form.resetFields();
                await navigate('/my-wallet');
            }else{
                
            }
        } else {
            toast.error(res?.payload?.messages?.message);
        }
    };

    // useEffect(() => {
    //     if (!isActive) {
    //         dispatch(logout());
    //     }
    // }, []);

    // if (isActive) {
    //     return <Navigate to="/onboarding" replace />;
    // }

    const handleRegister = async () => {
        await dispatch(cleanOrgObj());
        await dispatch(cleanOrgDocObj());
        await navigate('/registration');
    };

    return (
        <>
            <div className="flex w-full min-h-screen bg-white">
                <div className="flex flex-col w-full ">
                    <Header />
                    <div className="w-full h-full flex mt-[10.5rem] ">
                        <div className="flex flex-col w-full lg:px-0 px-3 items-center text-center">
                            <span className="heading_1">Login</span>
                            <span className="paragraph_1 mt-[1.13rem]">Welcome. Please fill in your details below.</span>

                            <Form
                                layout="vertical"
                                ref={formRef}
                                name="control-ref"
                                onFinish={onFinish}
                                style={{
                                    maxWidth: '100%',
                                    marginTop: '2.06rem',
                                    width: '32.25rem',
                                }}
                                form={form}
                            >
                                <Form.Item
                                    name="usrSecret"
                                    label="Code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Otp code is required to login',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter code sent to your phone" className="input" />
                                </Form.Item>

                                <div className="w-full flex flex-col items-center justify-center">
                                    <div className="flex justify-center w-[10.625rem] mt-[1.63rem]">
                                        <button className="cstm-btn-2" type="submit" disabled={authLoading ? true : false}>
                                            {authLoading ? <Spin /> : 'Login'}
                                        </button>
                                    </div>

                                    <div className="w-full justify-center flex mt-[1.19rem]">
                                        <span className="label_2 !text-[#0A0A0A]">Don’t have an account? </span>
                                        <button type="button" className=" label_2 !text-orangeYellows ml-[.1rem]" onClick={handleRegister}>
                                            Register
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

                <div className="hidden lg:flex w-full min-h-screen login-pic px-[3.19rem] py-[4.5rem]  flex-col">
                    <div className="typography">Seamless payment management </div>
                    <div className="typography2 w-[26.75rem] mt-[1rem]">The fastest way to manage payments across your departments</div>
                    <div className="mt-[3.87rem]">
                        <img src={img2} alt="img2" />
                    </div>
                    <div className="px-[10.38rem] mt-[-4rem]">
                        <img src={img3} alt="img3" />
                    </div>
                    <div className="typography-footer mt-[8.44rem] flex justify-end">Powered by Smart People Africa</div>
                </div>
            </div>
        </>
    );
}
