import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import VerificationModal from "./VerificationModal";
import { fetchSubwalletDropdown } from "../../../features/fetch/fetchSlice"; 
import { setActiveObj } from "../../../features/obj/objSlice";
import { walletTransferType } from "../../../data";

const { TextArea } = Input;

const TransferModal = ({
  open,
  handleCancel,
  handleFetchData,
  setopenSingle,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { subwalletData, mainWalletData, dropLoading } = useSelector(
    (state) => state.fetch
  );
  const { user } = useSelector((state) => state.auth);
  const { saving } = useSelector((state) => state.save);
  const { singlePaymentObj } = useSelector((state) => state.obj);
  const [paymentSourceName, setPaymentSourceName] = useState();
  const [benefitiaryName, setBenefitiaryName] = useState();

  const [transferType, setTransferType] = useState();

  const handleTransferType =async (value)=>{
   await setTransferType(value);
   await form.setFieldsValue({ targetWallet: "",targetWallet: "" });
  }


  const [openVerification, setopenVerification] = useState(false);
  async function handleCancelVerification() {
    await setopenVerification(false);
    await setopenSingle(true);
  }
  async function handleOpenVerification() {
    await setopenVerification(true);
    await setopenSingle(false);
    await handleCancel();
  }

  async function handleCancelSingle() {
    await handleCancel();
    await form.resetFields();
    await setShow();
  }

  
  const [formData, setFormData] = useState();
  const [show, setShow] = useState(singlePaymentObj?.paymentMethod);

  const onChangeOther = (value, fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  async function handleSelectChange(value, formName) {
    await setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  const onFinish = async (data) => {
    data.blkuType = "MPESA";
    data.paymentSourceName = paymentSourceName;
    data.benefitiaryName = benefitiaryName
    await dispatch(setActiveObj(data));
    await handleOpenVerification();
  };
 
  async function fetchSubWalletData() {
    dispatch(
      fetchSubwalletDropdown({
        url: `api/v1/wallet/sub/${user?.disbursementWalUid}`,
      })
    );
  }
console.log("walletTransferType",walletTransferType)
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={`Funds Transfer`}
        open={open}
        onOk={onFinish}
        onCancel={handleCancelSingle}
        width={850}
        maskClosable={false}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid lg:grid-cols-2 lg:gap-x-5 grid-cols-1">
            <Form.Item
              name="sourceWallet"
              className=""
              label={<span>Payment Source</span>}
              rules={[
                {
                  required: true,
                  message: "Please select payment source",
                },
              ]}
            >
              <Select
                className=""
                allowClear
                style={{
                  width: "100%",
                }}
                options={
                  subwalletData?.length > 0 &&
                  subwalletData?.map((item) => ({
                    value: item?.walUid,
                    label: item?.walName,
                  }))
                }
                onChange={(value) => {
                  const selectedItem = subwalletData?.find(
                    (item) => item?.walUid === value
                  );
                  setPaymentSourceName(selectedItem?.walName || "");
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label?.toLocaleLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
                onDropdownVisibleChange={() => {
                  fetchSubWalletData();
                }}
                dropdownRender={(menu) => (
                  <>
                    {dropLoading && (
                      <div
                        style={{
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        <Spin className="sms-spin" />
                      </div>
                    )}
                    {menu}
                  </>
                )}
              />
            </Form.Item>

            <Form.Item
              name="type"
              className=""
              label={<span>Transfer Type</span>}
              rules={[
                {
                  required: true,
                  message: "Please select transfer type",
                },
              ]}
            >
              <Select
                className=""
                allowClear
                style={{
                  width: "100%",
                }}
                options={
                  walletTransferType?.length > 0 &&
                  walletTransferType?.map((item) => ({
                    value: item?.value,
                    label: item?.label,
                  }))
                }
                onChange={(value) => {
                  handleTransferType(value) 
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label?.toLocaleLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
              />
            </Form.Item>
            {transferType === "WALLET" ? (
              <Form.Item
                name="targetWallet"
                className=""
                label={<span>Benefitiary Account</span>}
                rules={[
                  {
                    required: true,
                    message: "Please select Benefitiary Account",
                  },
                ]}
              >
                <Select
                  className=""
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  options={
                    subwalletData?.length > 0 &&
                    subwalletData?.map((item) => ({
                      value: item?.walUid,
                      label: item?.walName,
                    }))
                  }
                  onChange={(value) => {
                    const selectedItem = subwalletData?.find(
                      (item) => item?.walUid === value
                    );
                    setBenefitiaryName(selectedItem?.walName || "");
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  onDropdownVisibleChange={() => {
                    fetchSubWalletData();
                  }}
                />
              </Form.Item>
            ) : transferType === "SETTLE" ? (
              <Form.Item
                name="targetWallet"
                className=""
                label={<span>Settlement Account</span>}
                rules={[
                  {
                    required: true,
                    message: "Please select Settlement Account",
                  },
                ]}
              >
                <Select
                  className=""
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  options={
                    walletTransferType?.length > 0 &&
                    walletTransferType?.map((item) => ({
                      value: item?.value,
                      label: item?.label,
                    }))
                  }
                  onChange={(value) => {
                    const selectedItem = walletTransferType?.find(
                      (item) => item?.value === value
                    );
                    setBenefitiaryName(selectedItem?.label || "");
                  }}
                  // onChange={(value) => {
                  //   setTransferType(value);
                  // }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  onDropdownVisibleChange={() => {
                    fetchSubWalletData();
                  }}
                />
              </Form.Item>
            ) : null}

            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Enter amount",
                },
              ]}
            >
              <InputNumber
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                placeholder="Enter amount"
                className="input flex"
              />
            </Form.Item>
          </div>

          <Form.Item
            name="reason"
            label="Reason for transfer"
            rules={[
              {
                required: true,
                message: "Enter reason for transfer",
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder="Enter reason for transfer"
              className="input-textarea"
            />
          </Form.Item>

          <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <div className="w-[200px]">
                <button
                  key="back"
                  type="button"
                  onClick={handleCancelSingle}
                  className="cstm-btn"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[200px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn-2"
                  disabled={saving}
                >
                  {saving ? <Spin /> : "Next"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
      <VerificationModal
        handleFetchData={handleFetchData}
        open={openVerification}
        setopenVerification={setopenVerification}
        handleCancel={handleCancelVerification}
        handleCancelSingle={handleCancel} 
      />
    </>
  );
};
export default TransferModal;
