import React, { useRef, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header';

export default function RegistrationSuccess() {
    const [form] = Form.useForm();
    const formRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authLoading, user } = useSelector((state) => state.auth);

    const onFinish = async () => {
        await navigate('/login');
    };

    return (
        <>
            <div className="flex  w-full  min-h-screen bg-white">
                <div className="flex flex-col w-full ">
                    <Header />
                    <div className="w-full min-w-[24rem] h-full flex flex-col ">
                        <div aria-disabled={authLoading} className="flex flex-col items-center mt-[7.12rem]">
                            <div className="flex flex-col items-center w-full">
                                <h3 className="heading_1">Account Creation</h3>
                                <span className="zambia_login_text mt-[.75rem]">Your account has been created successfully</span>
                                <span className="zambia_login_text mt-[.75rem]">We have emailed you a list of documents you should submit for account approval</span>
                            </div>
                        </div>

                        <div className="w-full flex items-center justify-center">
                            <div className="w-[200px]">
                                <button onClick={onFinish} disabled={authLoading} className="cstm-btn-2 mt-[2.75rem]" type="button">
                                    {authLoading ? <Spin /> : 'Proceed'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
