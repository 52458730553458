import { Breadcrumb } from "antd";
import noCon from "../assets/img/noCon.png";
import { useNavigate } from "react-router-dom";

export default function NoDataTable({
  handleAddFolder,
  title,
  imgTitle,
  btnTile,
  btnIcon,
}) {
  function handleAdd() {
    if (btnTile) {
      handleAddFolder();
    }
  }
  const navigate = useNavigate();

  return (
    <div className=" ">
      <div className="product_request_title !text-[31px] mt-[1.25rem]">
        {title}
      </div>
      <div
        className="mt-[10px] mb-[3rem] bg-white w-full h-full rounded-[15px] border border-solid
				 border-[#cccccc] py-[60px] flex flex-col justify-center items-center cursor-pointer"
        onClick={handleAdd}
      >
        <div>
          <img src={noCon} alt="group-pic" className="object-contain" />
          <div className="paragraph flex items-center justify-center text-center">
            {imgTitle}{" "}
          </div>
        </div>

        {btnTile && (
          <div className="mt-[2rem] w-[270px]">
            <button className="cstm-btn-2">
             <img src={btnIcon} alt="btnIcon"/>
              {btnTile}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
