import { Descriptions, Dropdown, Input, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import svg38 from '../../../../assets/svg/svg38.svg';
import MaterialIcon from 'material-icons-react';
import { capitalize, dateForHumans, timeAmPm } from '../../../../utils';
import FilterModal from './FilterModal';
import svg56 from '../../../../assets/svg/svg56.svg';
import svg27 from '../../../../assets/svg/svg27.svg';
import toast from 'react-hot-toast';
import { save } from '../../../../features/save/saveSlice';
import ConfirmSubmitModal from '../../../../components/ConfirmSubmitModal';
import { fetchPaymentDetails } from '../../../../features/fetch/fetchSlice';
import DeleteModal from '../../../../components/DeleteModal';
import BreadCrumb from '../../../../layout/BreadCrumb';

export default function PaymentDetailsTable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { paymentDetails, dropLoading, paymentDetailsCount } = useSelector((state) => state.fetch);
    const { directHeaderObj } = useSelector((state) => state.obj);
    const { saving } = useSelector((state) => state.save);

    const [searchText, setSearchText] = useState('');

    const [open, setopen] = useState(false);

    function handleCancel() {
        setopen(false);
    }

    const [openSingle, setopenSingle] = useState(false);
    function handleCancelSingle() {
        setopenSingle(false);
    }
    async function handleOpenSingle() {
        await setopenSingle(true);
    }
    const handleEditSingle = () => {
        setopenSingle(true);
    };

    const handleDelete = async () => {
        // const res = await dispatch(
        //   deletePaymentDetails({
        //     pdId: prodd?.pdId,
        //   })
        // );
        // if (res?.payload?.success) {
        //   await toast.success(res?.payload?.messages?.message);
        //   await fetchPaymentDetailsData();
        //   await setIsModalOpenDelete(false);
        // } else {
        //   toast.error(res?.payload?.messages?.message);
        // }
    };
    const [filters, setFilters] = useState([]);
    const [formData, setFormData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModalFilter = () => {
        setIsModalOpen(true);
    };

    const handleClearFilters = async () => {
        await setFormData({});
        await dispatch(
            fetchPaymentDetails({
                url: '/api/v1/payments/fetch-bulk-payment-details',
            })
        );
        await setFilters([]);
    };

    const [prodd, setProdd] = useState('');
    const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
    const showModalDelete = async () => {
        setIsModalOpenDelete(true);
    };

    const [isModalOpenSubmit, setIsModalOpenSubmit] = useState(false);

    const handleConfirmation = async () => {
        await setIsModalOpenSubmit(true);
    };

    const handleSubmit = async () => {
        const res = await dispatch(
            save({
                url: `py/disbursePayment.action?phId=1`,
            })
        );
        if (res?.payload?.success) {
            await toast.success(res?.payload?.messages?.message);
            await setIsModalOpenSubmit(false);
            await navigate('/direct-payment');
        } else {
            toast.error(res?.payload?.messages?.message);
        }
    };

    const handleExport = () => {};

    const handleView = () => {};

    const columns = [ 
        {
            title: 'Benefitiary Account',
            dataIndex: 'blkBeneficiaryAccount',
            sorter: (a, b) => a.blkBeneficiaryAccount.localeCompare(b.blkBeneficiaryAccount),
        },
        {
            title: 'Beneficiary Bank',
            dataIndex: 'blkBeneficiaryBank',
            sorter: (a, b) => a.blkBeneficiaryBank.localeCompare(b.blkBeneficiaryBank),
        },
        {
            title: 'Beneficiary Details',
            dataIndex: 'blkBeneficiaryDetails',
            sorter: (a, b) => a.blkBeneficiaryDetails.localeCompare(b.blkBeneficiaryDetails),
        },
        {
            title: 'National ID Number',
            dataIndex: 'blkIdNumber',
            sorter: (a, b) => a.blkIdNumber.localeCompare(b.blkIdNumber),
        },
        {
            title: 'Number Post Fix',
            dataIndex: 'blkIdNumberPostfix',
            sorter: (a, b) => a.blkIdNumberPostfix.localeCompare(b.blkIdNumberPostfix),
        },
        {
            title: 'Payment Date',
            dataIndex: 'blkPaymentDate',
            sorter: (a, b) => a.blkPaymentDate.localeCompare(b.blkPaymentDate),
        },
        {
            title: 'Payment Reason',
            dataIndex: 'blkPaymentReason',
            sorter: (a, b) => a.blkPaymentReason.localeCompare(b.blkPaymentReason),
        },
        {
            title: 'Payment Type',
            dataIndex: 'blkPaymentType',
            sorter: (a, b) => a.blkPaymentType.localeCompare(b.blkPaymentType),
        },

        {
            title: 'Status',
            dataIndex: 'blkStatus',
            render: (item) => {
                return <div className={`${item === 'PENDING' ? 'bg-[#FFA500]' : 'bg-darkGreen'}  text-[#FFf]  inline-block rounded-[5px] px-2 py-1 w-auto`}>{item}</div>;
            },
        },
        {
            title: 'Thread Time',
            dataIndex: 'blkThreadTime',
            render: (item) => {
                return <div>{timeAmPm(item)}</div>;
            },
            sorter: (a, b) => a.blkThreadTime - b.blkThreadTime,
        },

        // {
        //     title: 'Actions',
        //     render: (item) => (
        //         <>
        //             <Dropdown
        //                 overlayStyle={{
        //                     width: '250px',
        //                 }}
        //                 trigger={'click'}
        //                 menu={{ items: settingItems }}
        //                 placement="bottom"
        //             >
        //                 <button onClick={() => setProdd(item)}>
        //                     <img src={svg27} alt="svg27" />
        //                 </button>
        //             </Dropdown>
        //         </>
        //     ),
        // },
    ];

    console.log('paymentDetails', paymentDetails);
    console.log('directHeaderObj', directHeaderObj);
    const items = [
        {
            key: '2',
            label: 'Created Date',
            children: <div>{dateForHumans(directHeaderObj?.blkuCreatedDate)}</div>,
        },
        {
            key: '3',
            label: 'Created Time',
            children: <div>{timeAmPm(directHeaderObj?.blkuCreatedTime)}</div>,
        },
        {
            key: '4',
            label: 'Payment Mode',
            children: directHeaderObj?.blkuPaymentMode,
        },
        {
            key: '5',
            label: 'Total Amount',
            children: directHeaderObj?.blkuTotalAmount,
        },
        {
            key: '6',
            label: 'Initiator',
            children: directHeaderObj?.transType,
        },
        {
            key: '6',
            label: 'Status',
            children: directHeaderObj?.blkuStatus,
        },
    ];

    const settingItemsQuickActions = [
        {
            key: '1',
            label: (
                <Link onClick={handleConfirmation} className="flex  text-[16px] font-sans  !text-lightBlack py-1">
                    Disburse Payment
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link onClick={handleOpenSingle} className="flex  text-[16px] font-sans  !text-lightBlack py-1">
                    Add details
                </Link>
            ),
        },
    ];

    const settingItems = [
        {
            key: '1',
            label: (
                <Link onClick={handleEditSingle} className="flex  text-[16px] font-sans  !text-lightBlack py-1">
                    Edit
                </Link>
            ),
        },

        {
            key: '2',
            label: (
                <div onClick={showModalDelete} className="flex  text-[16px] font-sans   !text-lightBlack py-1">
                    Delete
                </div>
            ),
        },
    ];

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    async function fetchPaymentDetailsData(page, size) {
        dispatch(
            fetchPaymentDetails({
                url: 'api/v1/payments/fetch-bulk-payment-details',
                limit: size ?? pageSize,
                start: page ?? pageIndex,
                blkBlkuUid: directHeaderObj?.blkuUid,
            })
        );
    } 

    const handleBack = async () => {
        await navigate('/direct-payment');
    };
    const breadList = [
        {
            title: (
                <div className="flex items-center gap-[.25rem] cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_627_13942)">
                            <path
                                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                                fill="black"
                                fillOpacity="0.45"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_627_13942">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span onClick={handleBack}>Direct Payments List</span>
                </div>
            ),
        },
        {
            title: capitalize('Direct Payments Details'),
        },
    ];

    useEffect(() => {
        fetchPaymentDetailsData();
    }, []);

    return (
        <div className=" ">
            <div className="justify-between items-center white_card">
                <BreadCrumb breadList={breadList} header={'Direct Payments Details'} info={'Manage payment details within your payment header'} />
            </div>

            <div className="bg-white rounded-[10px] mt-[1.25rem] h-full  min-h-[100vh]">
                <div className="flex flex-col w-full lg:px-10 px-3 ">
                    <div className="mt-[1.25rem]">
                        <Descriptions column={2} colon={false} title="Payment Header Information" items={items} />
                    </div>
                    <div className="flex justify-between items-center">
                        <h3 className="font-[700] text-[24px] text-lightBlack dash-title mt-[1.25rem]">Payment Details</h3>
                    </div>
                    <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
                        <div className="flex items-center">
                            <span>
                                {' '}
                                <button
                                    onClick={showModalFilter}
                                    type="button"
                                    className={`bg-transparent flex items-center gap-x-'1' ${Object?.keys(formData)?.length > 0 ? '!text-[#5688E5]' : 'inherit'}`}
                                >
                                    <MaterialIcon color="#141414" icon="filter_list" />
                                    Filters
                                </button>
                            </span>
                            {Object?.keys(formData)?.length > 0 && (
                                <span className="flex items-center text-[#5688E5] cursor-pointer ml-1">
                                    :{Object?.keys(formData)?.length}
                                    <img src={svg38} alt="svg38" onClick={handleClearFilters} />
                                </span>
                            )}
                        </div>
                         
                    </div>
                    <div className="dash-inner-page mt-[24px] max-w-full w-full overflow-x-auto">
                        <section>
                            <Table
                                rowSelection={false}
                                className="mt-[1.31rem] w-full"
                                scroll={{
                                    x: 800,
                                }}
                                rowKey={(record) => record?.pdId}
                                columns={columns}
                                dataSource={paymentDetails}
                                loading={dropLoading}
                                pagination={{
                                    position: ['bottomCenter'],
                                    current: pageIndex + 1,
                                    total: paymentDetailsCount,
                                    pageSize: pageSize,
                                    onChange: (page, size) => {
                                        setPageIndex(page - 1);
                                        setPageSize(size);
                                        fetchPaymentDetailsData(page - 1, size);
                                    },
                                    showSizeChanger: false,
                                    hideOnSinglePage: true,
                                }}
                            />
                        </section>
                    </div>
                </div>
            </div>

            <FilterModal isModalOpen={open} handleCancel={handleCancel} formData={formData} setFormData={setFormData} filters={filters} setFilters={setFilters} />

            {/* <PaymentDetailsModal
        handleFetchData={fetchPaymentDetailsData}
        open={openSingle}
        setopenSingle={setopenSingle}
        handleCancel={handleCancelSingle}
        prodd={prodd}
      /> */}

            <DeleteModal
                isModalOpen={isModalOpenDelete}
                setIsModalOpen={setIsModalOpenDelete}
                prodd={prodd}
                handleDelete={handleDelete}
                loading={dropLoading}
                content={`Are you sure you want to delete ${prodd?.phNo}?`}
                title={`Delete ${prodd?.phNo}`}
            />

            <ConfirmSubmitModal
                isModalOpen={isModalOpenSubmit}
                setIsModalOpen={setIsModalOpenSubmit}
                handleSubmit={handleSubmit}
                loading={saving}
                content={`Are you sure you want to disburse payment  ${prodd?.wefhNo} `}
            />
        </div>
    );
}
