import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { save } from "../../../../features/save/saveSlice";

const { TextArea } = Input;
const AddPaymentModal = ({ open, handleCancel, handleFetchData, prodd }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving } = useSelector((state) => state.save);
  const { apprvNames } = useSelector((state) => state.fetch);
  console.log("apprvNames", apprvNames);

  const onFinish = async (data) => {
    data.aprvId = prodd.aprvId ?? null;
    const res = await dispatch(
      save({
        ...data,

        url: "api/v1/approvals/save-approval-conf",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleFetchData();
      await handleCancel();
      await form.resetFields();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  useEffect(() => {
    if (prodd) {
      form.setFieldsValue(prodd);
    } else {
      form.resetFields();
    }
  }, [prodd, form]);

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={prodd ? `Edit ${prodd?.aprvName}` : "Add Approval Conf Details"}
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
          // initialValues={prodd}
        >
          <Form.Item
            name="aprvName"
            className=""
            label={<span>Approval Name</span>}
            rules={[
              {
                required: true,
                message: "Please select approval name",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                apprvNames?.length > 0 &&
                apprvNames?.map((item) => ({
                  value: item,
                  label: item,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onDropdownVisibleChange={() => {}}
            />
          </Form.Item>

          <Form.Item
            name="aprvNo"
            label="Approvers No"
            rules={[
              {
                required: true,
                message: "Enter Approvers No",
              },
            ]}
          >
            <InputNumber
              min={0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              className="input flex"
            />
          </Form.Item>

          <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <div className="w-[200px]">
                <button
                  key="back"
                  type="button"
                  onClick={handleCancel}
                  className="cstm-btn"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[200px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn-2"
                  disabled={saving}
                >
                  {saving ? <Spin /> : "Create"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddPaymentModal;
