import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Search from '../../../components/Search';
import { Checkbox, Form } from 'antd';

function Permissions() {
    const [form] = Form.useForm();
    const formRef = useRef(null);

    const { activeObj } = useSelector((state) => state.obj);
    const [searchInput, setsearchInput] = useState('');

    const [permissionValue, setPermissionValue] = useState([]);

    const onChange = (checkedValues) => {
        setPermissionValue(checkedValues);
    };

    const handleSearch = (val) => {
        setsearchInput(val);
    };
    const permissionData = {
        permission: [
            { label: 'Communication', value: 'view' },
            { label: 'Edit All', value: 'edit' },
            { label: 'Delete All', value: 'delete' },
        ],
    };

    return (
        <div className="white_card flex flex-col">
            <div className="heading_4">{activeObj?.custFirstname}’s Permissions</div>
            <div className="permission-card p-[1rem]  lg:w-[50%] w-full">
                <div className="typography-permission mt-[1.75rem] ">
                    Assign permissions to {activeObj?.custFirstname} {activeObj?.custLastname}
                </div>
                <div className="w-full mt-[.19rem]">
                    <Search text={'Search permission or keyword'} searchInput={searchInput} handleSearch={handleSearch} />
                </div>
                <div className="mt-[1.5rem]">
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please select a permission',
                            },
                        ]}
                    >
                        <Checkbox.Group className="filter_checkbox flex flex-col gap-y-2" options={permissionData?.permission} value={permissionValue} onChange={onChange} />
                    </Form.Item> 
                </div>
            </div>
        </div>
    );
}

export default Permissions;
