import React, { useState } from "react";
import { Card, Modal } from "antd";
import group from "../../../../assets/svg/Group.svg";
import upload from "../../../../assets/svg/upload csv.svg";
import { useNavigate } from "react-router-dom";
import AddGroupContactModal from "./AddGroupContactModal";

const HowToAddContactModal = ({
  open,
  handleCancel,
  uploadGraduateFile,
  handleFetchData,
}) => {
  const navigate = useNavigate();

  function handleUploadMembersFile() {
    navigate("/group-members/upload");
  }

  function handleUploadGraduateFile() {
    uploadGraduateFile();
  }

  const [openSingle, setopenSingle] = useState(false);
  async function handleOpenSingle() {
    await setopenSingle(true);
    await handleCancel();
  }
  async function handleCancelSingle() {
    await setopenSingle(false);
    await handleCancel();
  }

  return (
    <>
      <Modal
        className="mod mod-height"
        title="Add group members"
        open={open}
        onCancel={handleCancel}
        width={850}
      >
        <div className="grid grid-cols-2 gap-5 p-[41px]">
          <Card className="p-[70px cursor-pointer" onClick={handleOpenSingle}>
            <div className="text-center justify-center flex flex-col ">
              <img
                src={group}
                loading="lazy"
                alt="group"
                className="mx-auto w-[169.002px] h-[161.471px]"
              />

              <span className="text-black text-[20px] font-[500] font-dmSans leading-loose mt-[32px]">
                {" "}
                Complete a Form{" "}
              </span>
            </div>
          </Card>
          <Card
            className="p-[70px cursor-pointer"
            onClick={handleUploadMembersFile}
          >
            <div className="text-center justify-center flex flex-col ">
              <img
                src={upload}
                loading="lazy"
                alt="upload"
                className="mx-auto w-[169.002px] h-[161.471px]"
              />

              <span className="text-black text-[20px] font-[500] font-dmSans leading-loose mt-[32px]">
                {" "}
                Import excel file{" "}
              </span>
            </div>
          </Card>
        </div>
      </Modal>

      <AddGroupContactModal
        open={openSingle}
        handleFetchData={handleFetchData}
        handleCancel={handleCancelSingle}
      />
    </>
  );
};
export default HowToAddContactModal;
