import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL; 

const initialState = {
    deleteloading: false,
   
};

 

export const deletePaybill = createAsyncThunk(
    "staffSettingSlice/deletePaybill",
    async (data) => {
      const res = await axiosInstance
        .delete(`${url}/api/v1/payments/deletePaybill/${data?.pbUid}`)
        .then((res) => res.data);
      return res;
    }
  );

  export const deleteTariff = createAsyncThunk(
    "staffSettingSlice/deleteTariff",
    async (data) => {
      const res = await axiosInstance
        .delete(`${url}/api/v1/payments/deleteTariff/${data?.tarUid}`)
        .then((res) => res.data);
      return res;
    }
  );

  export const deleteTariffNegotiated = createAsyncThunk(
    "staffSettingSlice/deleteTariffNegotiated",
    async (data) => {
      const res = await axiosInstance
        .delete(`${url}/api/v1/payments/tariff-negotiated/deleteNegotiatedTariff/${data?.tarUid}`)
        .then((res) => res.data);
      return res;
    }
  );

  export const deleteGroupMember = createAsyncThunk(
    "staffSettingSlice/deleteGroupMember",
    async (data) => {
      const res = await axiosInstance
        .delete(`${url}/api/v1/payments/delete-payment-group-detail/${data?.pgdId}`)
        .then((res) => res.data);
      return res;
    }
  );
   
  export const deleteGroup = createAsyncThunk(
    "staffSettingSlice/deleteGroup",
    async (data) => {
      const res = await axiosInstance
        .delete(`${url}/api/v1/payments/delete-payment-group/${data?.pgId}`)
        .then((res) => res.data);
      return res;
    }
  );

  export const deleteApprvConfig = createAsyncThunk(
    "staffSettingSlice/deleteApprvConfig",
    async (data) => {
      const res = await axiosInstance
        .delete(`${url}/api/v1/approvals/delete-approval-conf/${data?.aprvId}`)
        .then((res) => res.data);
      return res;
    }
  );


export const deleteSlice = createSlice({
    name: 'delete',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
 
        
        .addCase(deletePaybill.pending, (state) => {
          state.deleteloading = true;
        })
        .addCase(deletePaybill.fulfilled, (state,action) => {
          state.deleteloading = false;
        })
        .addCase(deletePaybill.rejected, (state) => {
          state.deleteloading = false;
        })

        .addCase(deleteTariff.pending, (state) => {
            state.deleteloading = true;
          })
          .addCase(deleteTariff.fulfilled, (state,action) => {
            state.deleteloading = false;
          })
          .addCase(deleteTariff.rejected, (state) => {
            state.deleteloading = false;
          })

          .addCase(deleteTariffNegotiated.pending, (state) => {
            state.deleteloading = true;
          })
          .addCase(deleteTariffNegotiated.fulfilled, (state,action) => {
            state.deleteloading = false;
          })
          .addCase(deleteTariffNegotiated.rejected, (state) => {
            state.deleteloading = false;
          })
            

          .addCase(deleteGroupMember.pending, (state) => {
            state.deleteloading = true;
          })
          .addCase(deleteGroupMember.fulfilled, (state,action) => {
            state.deleteloading = false;
          })
          .addCase(deleteGroupMember.rejected, (state) => {
            state.deleteloading = false;
          })

          .addCase(deleteGroup.pending, (state) => {
            state.deleteloading = true;
          })
          .addCase(deleteGroup.fulfilled, (state,action) => {
            state.deleteloading = false;
          })
          .addCase(deleteGroup.rejected, (state) => {
            state.deleteloading = false;
          })

          .addCase(deleteApprvConfig.pending, (state) => {
            state.deleteloading = true;
          })
          .addCase(deleteApprvConfig.fulfilled, (state,action) => {
            state.deleteloading = false;
          })
          .addCase(deleteApprvConfig.rejected, (state) => {
            state.deleteloading = false;
          })
            
          
        
  
    },
});

export default deleteSlice.reducer;
export const {} = deleteSlice.actions;
