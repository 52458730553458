import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import FilterModal from './modal/FilterModal';
import svg38 from '../../../assets/svg/svg38.svg';
import svg27 from '../../../assets/svg/svg27.svg';
import withdrawal from '../../../assets/svg/withdrawalSvg.svg';
import FunnelSimple from '../../../assets/svg/FunnelSimple.svg';
import NoDataTable from '../../../components/NoDataTable';
import CardLoading from '../../../components/CardLoading';
import { Dropdown, Table } from 'antd';
import { capitalize, dateForHumans, timeAmPm } from '../../../utils';
import flash from '../../../assets/svg/Flash.svg';
import BreadCrumb from '../../../layout/BreadCrumb';
import { fetchMainWallet, fetchPayments } from '../../../features/fetch/fetchSlice';
import { cleanDirectHeaderObj, cleanSinglePaymentObj, setDirectHeaderObj } from '../../../features/obj/objSlice';
import eyeSvg from "../../../assets/svg/eye.svg";
import svg32 from "../../../assets/svg/svg32.svg";  
import BulkPaymentModal from './modal/BulkPaymentModal';
import { clearSaveObj } from '../../../features/save/saveSlice';

function BulkPaymentTable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { open, handleCancel, handleOpen } = useModalToggle();
    const moreInfo = useModalToggle();
    const [saveObj, setsaveObj] = useState({});

    const { loading, paymentsCount, walletStatementData,payments } = useSelector((state) => state.fetch);
    const { walletObj } = useSelector((state) => state.obj);
 

    const [openBulk, setOpenBulk] = useState(false);
    const handleOpenBulkPayment = async() => {
       await  setOpenBulk(true);
        await dispatch(cleanSinglePaymentObj())
    };
    const handleCancelBulkPayment = () => {
        setOpenBulk(false);
    };

    function handleInfo(item){
        setsaveObj(item)
        moreInfo.handleOpen()
    }

 async function handleClose() {
        await setsaveObj({});
        await moreInfo.handleCancel()
    }



    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);


    const [searchText, setSearchText] = useState('');
    const [prodd, setProdd] = useState();
    const [filters, setFilters] = useState([]);
    const [formData, setFormData] = useState({});
    const showModalFilter = () => {
        handleOpen();
    };

    const handleClearFilters = async () => {
        await setFormData({});
        await setFilters([]);
    };
 
    

    const handleView=async(item)=>{
        await dispatch(setDirectHeaderObj(item))
        await navigate("/bulk-payment-details")
    }
 
    const columns = [
        {
            title: 'Created Date',
            dataIndex: 'blkuCreatedDate',
            render: (item) => {
                return <div>{dateForHumans(item)}</div>;
            },
            sorter: (a, b) => a.blkuCreatedDate - b.blkuCreatedDate,
        },
        {
            title: 'Created Time',
            dataIndex: 'blkuCreatedTime',
            render: (item) => {
                return <div>{timeAmPm(item)}</div>;
            },
            sorter: (a, b) => a.blkuCreatedTime - b.blkuCreatedTime,
        }, 
     
        {
            title: 'Payment Mode',
            dataIndex: 'blkuPaymentMode',
            sorter: (a, b) => a.blkuPaymentMode.localeCompare(b.blkuPaymentMode),
        },
       
        {
            title: 'Total Amount',
            dataIndex: 'blkuTotalAmount',
            sorter: (a, b) => a.blkuTotalAmount - b.blkuTotalAmount,
        },
        {
            title: 'Intiator',
            dataIndex: 'transType',
            sorter: (a, b) => a.transType.localeCompare(b.transType),
        },
         
        {
            title: "Status",
            dataIndex: "blkuStatus",
            render: (item) => {
              return (
                <div
                  className={`${
                    item === "PENDING" ? "bg-[#FFA500]" : "bg-darkGreen"
                  }  text-[#FFf]  inline-block rounded-[5px] px-2 py-1 w-auto`}
                >
                  {item}
                </div>
              );
            },
          },
        //   {
        //     title: 'Approvers',
        //     render: (item) => (
        //         <button onClick={() => handleInfo(item)} type="button">
        //             <img src={svg32} alt="info" />
        //         </button>
        //     ),
        // },

        {
            title: 'Info',
            render: (item) => ( 
                        <button onClick={() => handleView(item)}>
                            <img src={svg32} alt="svg32" />
                        </button>  
            ),
        }, 
       
    ];

    const breadList = [
        {
            title: (
                <div className="flex items-center gap-[.25rem]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_627_13942)">
                            <path
                                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                                fill="black"
                                fillOpacity="0.45"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_627_13942">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>Home</span>
                </div>
            ),
            href: '/#/home',
        },
        {
            title: capitalize('Bulk Payments'),
        },
    ];

    async function fetchMainWalletData() {
        dispatch(fetchMainWallet());
    }
 

    async function fetchPaymentsData(page, size) {
        dispatch(
            fetchPayments({
                url: 'api/v1/payments/fetch-bulk-payments',
                limit: size ?? pageSize,
                start: page ?? pageIndex, 
                blkuEntryType:"BULK"
            })
        );
    }
 

    useEffect(() => {
        fetchPaymentsData();
    }, []);

    useEffect(()=>{
        fetchMainWalletData()
        dispatch(cleanDirectHeaderObj());
        dispatch(clearSaveObj())
    },[])

    if (loading) return <CardLoading />;


    return (
        <>
         <div className="flex flex-col w-full h-full overflow-y-scroll">
        <div className="mb-10">
             <div className="justify-between items-center white_card">
                    <BreadCrumb
                        breadList={breadList}
                        header={'Bulk Payments'}
                        info={'Here’s an overview of your bulk payments'}
                        btn={true}
                        btnTitle="Initiate Payment"
                        btnSvg={flash} 
                        onClick={handleOpenBulkPayment}
                    />
                </div>

            {payments && payments?.length > 0 ? (
                <div className="bg-white rounded-[10px] mt-[1.25rem] h-full">
                    <div className="flex flex-col w-full lg:px-10 px-3 ">
                        <h3 className="font-[700] text-[24px] text-lightBlack dash-title mt-[1.25rem]">Bulk  Details</h3>
                        <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
                            <div className="flex items-center">
                                <span>
                                    {' '}
                                    <button
                                        onClick={showModalFilter}
                                        type="button"
                                        className={`bg-transparent flex items-center gap-x-'1' ${Object?.keys(formData)?.length > 0 ? '!text-[#5688E5]' : 'inherit'}`}
                                    >
                                        <img src={FunnelSimple} alt="FunnelSimple" />
                                        Filters
                                    </button>
                                </span>
                                {Object?.keys(formData)?.length > 0 && (
                                    <span className="flex items-center text-[#5688E5] cursor-pointer ml-1">
                                        :{Object?.keys(formData)?.length}
                                        <img src={svg38} alt="svg38" onClick={handleClearFilters} />
                                    </span>
                                )}
                            </div>
 
                        </div>
                        <div className="dash-inner-page mt-[24px] max-w-full w-full overflow-x-auto">
                            <section>
                                <Table
                                    rowSelection={false}
                                    className="mt-[1.31rem] w-full"
                                    scroll={{
                                        x: 800,
                                    }}
                                    rowKey={(record) => record?.blkBlkuUid}
                                    columns={columns}
                                    dataSource={payments}
                                    loading={loading}
                                    pagination={{
                                        position: ['bottomCenter'],
                                        current: pageIndex + 1,
                                        total: paymentsCount,
                                        pageSize: pageSize,
                                        onChange: (page, size) => {
                                            setPageIndex(page - 1);
                                            setPageSize(size);
                                            fetchPaymentsData(page - 1, size);
                                        },
                                        showSizeChanger: false,
                                        hideOnSinglePage: true,
                                    }}
                                />
                            </section>
                        </div>
                    </div>
                </div>
            ) : (
                <NoDataTable title="" imgTitle="Direct payments be displayed here once available" />
            )} 
        </div>
         <FilterModal isModalOpen={open} handleCancel={handleCancel} formData={formData} setFormData={setFormData} filters={filters} setFilters={setFilters} />
         <BulkPaymentModal handleFetchData={fetchPaymentsData} open={openBulk}  setopenSingle={setOpenBulk} handleCancel={handleCancelBulkPayment} />
         </div>
         </>
    );
}

export default BulkPaymentTable;
