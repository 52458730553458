import React from 'react';
import logo from '../../assets/img/Main logo.png';
import { Progress } from 'antd';
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate =  useNavigate()
    const handleLogin=()=>{
        navigate('/login')
    }
    return ( 
            <div className=" px-[2.06rem] py-[1.88rem] w-full cursor-pointer">
                <img src={logo} className="w-[7.125rem] h-[1.6875rem] object-cover" alt="image2" onClick={handleLogin} />
            </div> 
    );
}

export default Header;
