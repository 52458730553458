import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const initialState = {
	saving: false,
	bulkDetails:[],
	bulPaymentHeader:{}
};

const url = process.env.REACT_APP_API_BASE_URL;


export const save = createAsyncThunk('saveSlice/save/ordinary', async (data, { rejectWithValue }) => {
	let saveUrl = data.url;
	delete data.url;
	try {
		const response = await axiosInstance.post(`${url}/${saveUrl}`, data)
		if (!response.data.success) {
			return rejectWithValue(response.data);
		} 
		return response.data;
	} catch (error) { 
		return rejectWithValue(error.response.data);
	}
  });

//   export const save = createAsyncThunk('saveSlice/saveOtp', async (data) => {
// 	let saveUrl = data.url;
// 	delete data.url;
// 	const res = await axiosInstance
// 		.post(`${url}/${saveUrl}`, data)
// 		.then((res) => res?.data);
// 	return res;
// });

export const saveFileUpload = createAsyncThunk(
	'saveSlice/save/saveFileUpload', 
	async ({ url, formData }, { rejectWithValue }) => {
	  try {
		const response = await axiosInstance.post(url, formData, {
		  headers: {
			"Content-Type": "multipart/form-data",
		  },
		});
		
		if (!response.data.success) {
		  return rejectWithValue(response.data);
		}
		
		return response.data;
	  } catch (error) {
		return rejectWithValue(error.response?.data);
	  }
	}
  );
  

  export const saveFileUploadBulk = createAsyncThunk(
	'saveSlice/save/saveFileUploadBulk', 
	async ({ url, formData }, { rejectWithValue }) => {
	  try {
		const response = await axiosInstance.post(url, formData, {
		  headers: {
			"Content-Type": "multipart/form-data",
		  },
		});
		
		if (!response.data.success) {
		  return rejectWithValue(response.data);
		} 
		return response.data;
	  } catch (error) {
		return rejectWithValue(error.response?.data);
	  }
	}
  );

  export const saveExcel = createAsyncThunk(
	'saveSlice/save/saveExcel', 
	async ({ url, formData }, { rejectWithValue }) => {
	  try {
		const response = await axiosInstance.post(url, formData, {
		  headers: {
			"Content-Type": "multipart/form-data",
		  },
		});
		
		if (!response.data.success) {
		  return rejectWithValue(response.data);
		} 
		return response.data;
	  } catch (error) {
		return rejectWithValue(error.response?.data);
	  }
	}
  );
 
  
 
export const saveSlice = createSlice({
	name: 'save',
	initialState,
	reducers: {
		clearSaveObj: () => {
            return { ...initialState };
        },
	},
	extraReducers: (builder) => {
		builder
			 
			.addCase(save.pending, (state) => {
				state.saving = true;
			})
			.addCase(save.fulfilled, (state) => {
				state.saving = false;
			})
			.addCase(save.rejected, (state) => {
				state.saving = false;
			})
 
			.addCase(saveFileUpload.pending, (state) => {
				state.saving = true;
			})
			.addCase(saveFileUpload.fulfilled, (state, action) => {
				state.saving = false; 
			})
			.addCase(saveFileUpload.rejected, (state) => {
				state.saving = false; 
			})


			.addCase(saveFileUploadBulk.pending, (state) => {
				state.saving = true;
			
			})
			.addCase(saveFileUploadBulk.fulfilled, (state, action) => {
				state.saving = false; 
				state.bulkDetails = action.payload?.data?.bulkPay?.bulkPaymentUploadDetails
				state.bulPaymentHeader = action.payload?.data?.bulkPay?.bulPayment
			})
			.addCase(saveFileUploadBulk.rejected, (state) => {
				state.saving = false; 
				state.bulkDetails = []
				state.bulPaymentHeader ={}
			})

			.addCase(saveExcel.pending, (state) => {
				state.saving = true;
			})
			.addCase(saveExcel.fulfilled, (state, action) => {
				state.saving = false;
			})
			.addCase(saveExcel.rejected, (state) => {
				state.saving = false;
			})
			
	},
});

export default saveSlice.reducer;
export const {clearSaveObj} = saveSlice.actions;
