import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerWallets } from "../../../features/fetch/fetchSlice";
import CardLoading from "../../../components/CardLoading";
import { setWalletObj } from "../../../features/obj/objSlice";
import { dateForHumans, numberWithCommas } from "../../../utils";
import thermometer from "../../../assets/svg/thermometer.svg";
import { useNavigate } from "react-router-dom";
import NoDataTable from "../../../components/NoDataTable";

function CustomerWallets() {
  const { customerWallets, loading } = useSelector((state) => state.fetch);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleViewWallet = async (item) => {
    await dispatch(setWalletObj(item));
    await navigate("/my-wallet-view");
  };

  async function fetchCustomerWalletsData() {
    dispatch(
      fetchCustomerWallets({
        url: `api/v1/customers/fetch-customer-wallets`,
      })
    );
  }
  useEffect(() => {
    fetchCustomerWalletsData();
  }, []);

  console.log("customerWallets", customerWallets);

  if (loading) return <CardLoading />;
  return (
    <div className="white_card flex flex-col">
      {customerWallets && customerWallets?.length > 0 ? (
        customerWallets?.map((item, key) => (
          <div>
            <div className="account_card_alt !w-[90%] mt-[.44rem]">
              <div className="flex items-center justify-between w-full">
                <div>
                  <div className="w-full flex justify-between items-center">
                    <div className="flex flex-col">
                      <span className="label_1">{item?.walName}</span>
                      <span className="paragraph_1"> {item?.walStatus}</span>
                    </div>
                  </div>

                  <span className="paragraph_2 !font-bold !text-[#141414] mt-[1.5rem]">
                    Summary
                  </span>
                  <div className="w-full flex items-center gap-[.75rem]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <g filter="url(#filter0_d_1248_13000)">
                        <circle cx="8" cy="4.5" r="4" fill="#34B849" />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_1248_13000"
                          x="0"
                          y="0.5"
                          width="16"
                          height="16"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_1248_13000"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_1248_13000"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <span className="paragraph_1">
                      Wallet Amount | {item?.walCurrency}{" "}
                      {numberWithCommas(item?.walAmount)}
                    </span>
                  </div>
                  <div className="w-full flex items-center gap-[.75rem]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <g filter="url(#filter0_d_1248_12913)">
                        <circle cx="8" cy="4.5" r="4" fill="#C72C3B" />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_1248_12913"
                          x="0"
                          y="0.5"
                          width="16"
                          height="16"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_1248_12913"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_1248_12913"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <span className="paragraph_1">
                      Created Date |{dateForHumans(item?.wuCreatedDate)}
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  className="view_more_text flex cursor-pointer  justify-end"
                  onClick={() => handleViewWallet(item)}
                >
                  View details
                </button>
              </div>
            </div>
            {key < customerWallets?.length - 1 && (
        <div className="ml-[1.5rem]">
          <img src={thermometer} alt="account-line" />
        </div>
      )}
            
          </div>
        ))
      ) : (
        <NoDataTable
          title=""
          imgTitle="Customer wallets will be displayed here once available"
        />
      )}
    </div>
  );
}

export default CustomerWallets;
