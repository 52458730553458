import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Input, Spin, Select, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { save } from "../../../features/save/saveSlice";
import { fetchCustomerStatus } from "../../../features/fetch/fetchSlice";
import { cityData, GenderData, maritalStatusData } from "../../../data";
import countryData from "../../../countries.json";
import dayjs from "dayjs";
import { setRefetchKey } from "../../../features/global/globalSlice";
import { setActiveObj } from "../../../features/obj/objSlice";
import { Checkbox } from "antd";

const UsersAdd = ({ prodd, setactive }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { saving } = useSelector((state) => state.save);
  const { userStatus, customerStatus } = useSelector((state) => state.fetch);
  const { activeObj } = useSelector((state) => state.obj);

  const [data, setdata] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showForeign, setShowForeign] = useState(activeObj?.custAsylumRefNumber || activeObj?.custAsylumRefNumber ? true :  false);
  const onChangeCheckbox = (e) => {
    if (e.target.checked) {
      setShowForeign(true);
    } else setShowForeign(false);
  };

  function handleCancel() {
    navigate("/users");
  }
  const onFinish = async (data) => {
    data.custBirthCountry = "KE";
    data.custId = activeObj?.custId ? activeObj?.custId : null;
    const res = await dispatch(
      save({
        ...data,
        url: "api/v1/customers/save-customer",
      })
    );

    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await form.resetFields();
      await setactive("2");
      await dispatch(setRefetchKey("2"));
      await dispatch(setActiveObj(res?.payload?.data?.result));
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  function fetchDistinctStatusData() {
    dispatch(fetchCustomerStatus());
  }

  useEffect(() => {
    form.setFieldsValue({
      ...activeObj,
      custAsylumDateOfIssuance: activeObj?.custAsylumDateOfIssuance
        ? dayjs(new Date(activeObj?.custAsylumDateOfIssuance))
        : null,
      custNationalIdentityDateOfIssuance:
        activeObj?.custNationalIdentityDateOfIssuance
          ? dayjs(new Date(activeObj?.custNationalIdentityDateOfIssuance))
          : null,
      custPassportDateOfIssuance: activeObj?.custPassportDateOfIssuance
        ? dayjs(new Date(activeObj?.custPassportDateOfIssuance))
        : null,
      custPassportExpiry: activeObj?.custPassportExpiry
        ? dayjs(new Date(activeObj?.custPassportExpiry))
        : null,
      custAsylumPaperExpiry: activeObj?.custAsylumPaperExpiry
        ? dayjs(new Date(activeObj?.custAsylumPaperExpiry))
        : null,
    });
  }, [activeObj, form]);

  const initialFormValues = {
    ...activeObj,
    custAsylumDateOfIssuance: activeObj?.custAsylumDateOfIssuance
      ? dayjs(new Date(activeObj?.custAsylumDateOfIssuance))
      : null,
    custNationalIdentityDateOfIssuance:
      activeObj?.custNationalIdentityDateOfIssuance
        ? dayjs(new Date(activeObj?.custNationalIdentityDateOfIssuance))
        : null,
    custPassportDateOfIssuance: activeObj?.custPassportDateOfIssuance
      ? dayjs(new Date(activeObj?.custPassportDateOfIssuance))
      : null,
    custPassportExpiry: activeObj?.custPassportExpiry
      ? dayjs(new Date(activeObj?.custPassportExpiry))
      : null,
    custAsylumPaperExpiry: activeObj?.custAsylumPaperExpiry
      ? dayjs(new Date(activeObj?.custAsylumPaperExpiry))
      : null,
  };

  return (
    <div className="w-full ">
      <div className="white_card">
        <div className="w-full">
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className=""
            style={{
              maxWidth: "100%",
            }}
            form={form}
            initialValues={initialFormValues}
          >
            <div className="flex items-center gap-x-5">
              <div className="bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center">
                <span className="paragraph_paragraph_bb08">1</span>
              </div>
              <span className="paragraph_paragraph_bb13">Personal Details</span>
            </div>

            <div className="ml-[5rem] grid lg:grid-cols-2 grid-cols-1 lg:gap-x-10">
              <Form.Item
                name="custFirstname"
                label={" First Name"}
                rules={[
                  {
                    required: true,
                    message: "Please add first name",
                  },
                ]}
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                name="custMiddleName"
                label={"Middle Name"}
                rules={[
                  {
                    required: true,
                    message: "Please add Middle name",
                  },
                ]}
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                name="custLastname"
                label={"Last Name"}
                rules={[
                  {
                    required: true,
                    message: "Please add last name",
                  },
                ]}
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                name="custTitle"
                rules={[
                  {
                    required: true,
                    message: "Tile be provided",
                  },
                ]}
                label="Title"
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                name="custGender"
                className=""
                label={"Gender"}
                rules={[
                  {
                    required: true,
                    message: "Please select gender",
                  },
                ]}
              >
                <Select
                  className=""
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  options={
                    GenderData?.length > 0 &&
                    GenderData?.map((item) => ({
                      value: item?.value,
                      label: item?.label,
                    }))
                  }
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                />
              </Form.Item>

              <Form.Item
                name="custMaritalStatus"
                className=""
                label={"Marital Status"}
                rules={[
                  {
                    required: false,
                    message: "Please select Marital Status",
                  },
                ]}
              >
                <Select
                  className=""
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  options={
                    maritalStatusData?.length > 0 &&
                    maritalStatusData?.map((item) => ({
                      value: item?.value,
                      label: item?.label,
                    }))
                  }
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                />
              </Form.Item>

              <Form.Item
                name="custBirthCity"
                className=""
                label={"Birth City"}
                rules={[
                  {
                    required: false,
                    message: "Please select birth city",
                  },
                ]}
              >
                <Select
                  className=""
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  options={
                    cityData?.length > 0 &&
                    cityData?.map((item) => ({
                      value: item?.value,
                      label: item?.label,
                    }))
                  }
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                name="custSchoolName"
                rules={[
                  {
                    required: false,
                    message: "School Name must be provided",
                  },
                ]}
                label="School Name"
              >
                <Input className="input" />
              </Form.Item>

              <Checkbox checked={showForeign} onChange={onChangeCheckbox}>
                Are you a foreigner ?{" "}
              </Checkbox>
            </div>
            <div className="flex items-center gap-x-5 mt-10">
              <div className="bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center">
                <span className="paragraph_paragraph_bb08">2</span>
              </div>
              <span className="paragraph_paragraph_bb13">Contact Details</span>
            </div>
            <div className="ml-[5rem] grid lg:grid-cols-2 grid-cols-1 lg:gap-x-10">
              <Form.Item
                name="custEmail"
                label={" Email"}
                rules={[
                  {
                    required: true,
                    message: "Please add email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input type="email" className="input" />
              </Form.Item>

              <Form.Item
                name="custMobileNumber"
                label={"Phone No "}
                rules={[
                  {
                    required: true,
                    message: "Please add primary phone no",
                  },
                ]}
              >
                <PhoneInput
                  country="ke"
                  onlyCountries={["ke"]}
                  countryCodeEditable={false}
                  className="input rounded-[6px] border !border-[#cacaca] !h-[42px]"
                />
              </Form.Item>

              <Form.Item
                name="custPhone2"
                label={"Phone No2 "}
                rules={[
                  {
                    required: false,
                    message: "Please add secondary phone no",
                  },
                ]}
              >
                <PhoneInput
                  country="ke"
                  onlyCountries={["ke"]}
                  countryCodeEditable={false}
                  className="input rounded-[6px] border !border-[#cacaca] !h-[42px]"
                />
              </Form.Item>
              {!showForeign && (
                <>
                  {" "}
                  <Form.Item
                    name="custNationalId"
                    label={" National ID"}
                    rules={[
                      {
                        required: true,
                        message: "Please add national identification no",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      maxLength={8}
                      minLength={6}
                      className="input"
                    />
                  </Form.Item>
                  <Form.Item
                    name="custNationalIdentityDateOfIssuance"
                    rules={[
                      {
                        required: false,
                        message: "Field is required",
                      },
                    ]}
                    label="National Identity Date of Issuance"
                  >
                    <DatePicker className="input" />
                  </Form.Item>
                </>
              )}
            </div>

            {showForeign && (
              <>
                {" "}
                <div className="flex items-center gap-x-5 mt-10">
                  <div className="bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center">
                    <span className="paragraph_paragraph_bb08">3</span>
                  </div>
                  <span className="paragraph_paragraph_bb13">
                    Foreigner Details
                  </span>
                </div>
                <div className="ml-[5rem] grid lg:grid-cols-2 grid-cols-1 lg:gap-x-10">
                  <Form.Item
                    name="custAsylumRefNumber"
                    rules={[
                      {
                        required: false,
                        message: "Field is required",
                      },
                    ]}
                    label="Asylum RefNumber"
                  >
                    <Input className="input" />
                  </Form.Item>

                  <Form.Item
                    name="custAsylumDateOfIssuance"
                    rules={[
                      {
                        required: false,
                        message: "Field is required",
                      },
                    ]}
                    label="Asylum Issuance Date"
                  >
                    <DatePicker format={"DD MMM YYYY"} className="input" />
                  </Form.Item>

                  <Form.Item
                    name="custAsylumPaperExpiry"
                    rules={[
                      {
                        required: false,
                        message: "Field is required",
                      },
                    ]}
                    label="Asylum Expiry Date"
                  >
                    <DatePicker className="input" />
                  </Form.Item>

                  <Form.Item
                    name="custPassportNumber"
                    rules={[
                      {
                        required: false,
                        message: "Field is required",
                      },
                    ]}
                    label="Passport Number"
                  >
                    <Input className="input" />
                  </Form.Item>

                  <Form.Item
                    name="custPassportCountry"
                    className=""
                    label={"Passport country"}
                    rules={[
                      {
                        required: false,
                        message: "Please select passport country",
                      },
                    ]}
                  >
                    <Select
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      options={
                        countryData?.length > 0 &&
                        countryData?.map((item) => ({
                          value: item?.name,
                          label: item?.name,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="custPassportPlaceOfIssuance"
                    rules={[
                      {
                        required: false,
                        message: "Passport Place of Issuance is required",
                      },
                    ]}
                    label="Passport Place of Issuance"
                  >
                    <Input className="input" />
                  </Form.Item>

                  <Form.Item
                    name="custPassportDateOfIssuance"
                    rules={[
                      {
                        required: false,
                        message: "Field is required",
                      },
                    ]}
                    label="Passport Date of Issuance"
                  >
                    <DatePicker className="input" />
                  </Form.Item>

                  <Form.Item
                    name="custPassportExpiry"
                    rules={[
                      {
                        required: false,
                        message: "Field is required",
                      },
                    ]}
                    label="Passport Expiry Date"
                  >
                    <DatePicker className="input" />
                  </Form.Item>

                  {/* <Form.Item
      name="custStatus"
      className=""
      label={" Status"
      }
      rules={[
          {
              required:false,
              message: 'Please select status',
          },
      ]}
  >
      <Select
          className=""
          allowClear
          style={{
              width: '100%',
          }}
          options={
              customerStatus?.length > 0 &&
              customerStatus?.map((item) => ({
                  value: item?.ctmStatus,
                  label: item?.ctmStatus,
              }))
          }
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label?.toLocaleLowerCase() ?? '').includes(input?.toLocaleLowerCase())}
          onDropdownVisibleChange={() => {
              fetchDistinctStatusData();
          }}
      />
  </Form.Item> */}
                </div>
              </>
            )}

            <div className="flex justify-between mt-[48px] ">
              <div className="justify-start"></div>
              <div className="justify-end flex items-center mb-[58px] gap-x-5">
                <div className="w-[150px] ">
                  <button
                    type="button"
                    key="back"
                    onClick={handleCancel}
                    className="cstm-btn"
                  >
                    Cancel
                  </button>
                </div>

                <div className="w-[150px]">
                  <button key="submit" type="submit" className="cstm-btn-2">
                    {saving ? <Spin /> : prodd?.usrId ? "Update" : "Create"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default UsersAdd;
