import { Descriptions, Modal, Spin } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OtpModal from "./OtpModal";
import { save } from "../../../../features/save/saveSlice";
import toast from "react-hot-toast";

function VerificationModal({
  open,
  handleCancel,
  handleFetchData,
  setopenVerification,
  handleCancelSingle,
}) {
  const { singlePaymentObj } = useSelector((state) => state.obj);
  const { saving } = useSelector((state) => state.save);
  const dispatch = useDispatch();

  const [openOtp, setopenOtp] = useState(false);
  async function handleCancelOtp() {
    await setopenOtp(false);
    await setopenVerification(true);
  }

  async function handleCancelAll() {
    await setopenOtp(false);
    await setopenVerification(false);
    await handleCancelSingle();
  }

  async function handleOpenOtp() {
    await setopenOtp(true);
    await setopenVerification(false);
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        url: "api/v1/payments/send-payment-otp",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleOpenOtp();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const itemsBank = [
    {
      key: "1",
      label: "Payment Method",
      children: singlePaymentObj?.blkuType,
    },
    // {
    //     key: '2',
    //     label: 'Payment Mode',
    //     children: singlePaymentObj?.blkuPmUid,
    // },
    {
      key: "2",
      label: "Mode",
      children: singlePaymentObj?.paymentModeName,
    },
    // {
    //     key: '3',
    //     label: 'Source',
    //     children: singlePaymentObj?.blkuWalUid,
    // },
    {
      key: "3",
      label: "Source",
      children: singlePaymentObj?.paymentSourceName,
    },

    {
      key: "4",
      label: "Amount",
      children: singlePaymentObj?.blkPaymentAmount,
    },
    // {
    //     key: '5',
    //     label: 'Bank Name',
    //     children: singlePaymentObj?.blkBnkUid,
    // },
    {
      key: "5",
      label: "Bank Name",
      children: singlePaymentObj?.bankName,
    },
    {
      key: "7",
      label: "Account Number",
      children: singlePaymentObj?.blkBeneficiaryAccount,
    },
    {
      key: "8",
      label: "Account Name",
      children: singlePaymentObj?.blkBeneficiaryDetails,
    },
    {
      key: "9",
      label: "Reason",
      children: singlePaymentObj?.blkPaymentReason,
    },
    {
      key: "10",
      label: "ID Number",
      children: singlePaymentObj?.blkIdNumber,
    },
  ];

  const itemsMobile = [
    {
      key: "0",
      label: "Payment Type",
      children: singlePaymentObj?.blkuType,
    },
    // {
    //     key: '1',
    //     label: 'Payment Mode',
    //     children: singlePaymentObj?.blkuPmUid,
    // },
    {
      key: "2",
      label: "Payment Source",
      children: singlePaymentObj?.paymentSourceName,
    },
    {
      key: "3",
      label: "Payment Amount",
      children: singlePaymentObj?.blkPaymentAmount,
    },
    {
      key: "5",
      label: "Payment Reason",
      children: singlePaymentObj?.blkPaymentReason,
    },
    {
      key: "6",
      label: "ID Number",
      children: singlePaymentObj?.blkIdNumber,
    },
    {
      key: "6",
      label: "Receiver Number",
      children: singlePaymentObj?.blkReceiverMobileNo,
    },
  ];

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={`Verification`}
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
        maskClosable={false}
      >
        <p className="text-[15px] text-[#000] font-[600] mb-8 font-dmSans">
          Would you like to make the following transfer?
        </p>

        <div className="mt-[1.25rem]">
          {singlePaymentObj?.blkuType === "BANK" ? (
            <Descriptions
              column={1}
              colon={false}
              title="Single Payment Information"
              items={itemsBank}
            />
          ) : singlePaymentObj?.blkuType === "MPESA" ? (
            <Descriptions
              column={1}
              colon={false}
              title="Single Payment Information"
              items={itemsMobile}
            />
          ) : null}
        </div>

        <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
          <div className="justify-start"></div>
          <div className="justify-end flex items-center gap-x-2">
            <div className="w-[150px]">
              <button
                key="back"
                type="button"
                onClick={handleCancel}
                className="cstm-btn"
              >
                Previous
              </button>
            </div>

            <div className="w-[150px]">
              <button
                key="submit"
                type="submit"
                className="cstm-btn-2"
                onClick={onFinish}
                disabled={saving}
              >
                {saving ? <Spin /> : "Next"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <OtpModal
        handleFetchData={handleFetchData}
        open={openOtp}
        handleCancel={handleCancelOtp}
        handleCancelAll={handleCancelAll}
      />
    </>
  );
}

export default VerificationModal;
