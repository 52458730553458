import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import VerificationModal from './VerificationModal';
import { setSinglePaymentObj } from '../../../../features/obj/objSlice';
import { fetchBanks, fetchMainWallet, fetchPaymentModes, fetchSubwallet, fetchSubwalletDropdown } from '../../../../features/fetch/fetchSlice';
import { save } from '../../../../features/save/saveSlice';

const { TextArea } = Input;

const BankPaymentModal = ({ open, handleCancel, handleFetchData, setopenSingle }) => {
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { banks, paymentModes, dropLoading, mainWalletData, subwalletData } = useSelector((state) => state.fetch);
    const { saving } = useSelector((state) => state.save);
    const { singlePaymentObj } = useSelector((state) => state.obj);
    const { user } = useSelector((state) => state.auth);

    const [paymentSourceName, setPaymentSourceName] = useState();
    const [paymentModeName, setPaymentModeName] = useState();
    const [bankName, setBankName] = useState();
    const [bankCode, setBankCode] = useState();
    const [accntNumber, setAccntNumber] = useState();

    const [openVerification, setopenVerification] = useState(false);
    async function handleCancelVerification() {
        await setopenVerification(false);
        await setopenSingle(true);
    }
    async function handleOpenVerification() {
        await setopenVerification(true);
        await setopenSingle(false);
        await handleCancel();
    }

    async function handleCancelSingle() {
        await handleCancel();
        await form.resetFields();
    }

    const [formData, setFormData] = useState();

    const onChangeOther = (value, fieldName) => {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    async function handleSelectChange(value, formName) {
        await setFormData((prevData) => ({
            ...prevData,
            [formName]: value,
        }));
    }
    const onFinish = async (data) => {
        data.blkuType = 'BANK';
        data.paymentSourceName = paymentSourceName;
        data.paymentModeName = paymentModeName;
        data.bankName = bankName;
        await dispatch(setSinglePaymentObj(data));
        await handleOpenVerification();
 
    };

    async function fetchBanksData() {
        await dispatch(
            fetchBanks({
                url: 'api/v1/payments/fetch-banks',
            })
        );
    }
    async function fetchPaymentModesData() {
        await dispatch(
            fetchPaymentModes({
                url: 'api/v1/payments/fetch-payment-modes',
            })
        );
    } 
    async function handleValidValidate() {
        if(!bankCode ){
            toast.error("Please select a bank")
            return
          }
          if(!accntNumber){
            toast.error("Please provide a bank number")
              return
          }
        if (bankCode && accntNumber) {
            const res = await dispatch(
                save({
                    url: 'api/v1/payments/validate-account', 
                    bankCode: bankCode,
                    beneficiaryAccount: accntNumber,
                })
            );
            if (res?.payload?.data?.result?.status === 'SUCCESS') {
                form.setFieldsValue({
                    blkBeneficiaryDetails: res?.payload?.data?.result?.benName,
                }); 
            } else {
                await form.setFieldsValue({
                    blkBeneficiaryDetails: null,
                });
                await toast.error('Account not found type the account name');
            }
        }
    }

    async function fetchSubWalletData() {
        dispatch(
            fetchSubwalletDropdown({
                url: `api/v1/wallet/sub/${user?.disbursementWalUid}`,
            })
        );
    }

    return (
        <>
            <Modal className="modal-btn mod-height mod-footer" title={`Direct Payment`} open={open} onOk={onFinish} onCancel={handleCancelSingle} width={850} maskClosable={false}>
                <Form
                    layout="vertical"
                    ref={formRef}
                    name="control-ref"
                    onFinish={onFinish}
                    className=" "
                    style={{
                        maxWidth: '100%',
                    }}
                    form={form}
                    // initialValues={singlePaymentObj}
                >
                    <div className="grid lg:grid-cols-2 lg:gap-x-5 grid-cols-1">
                        <Form.Item
                            name="blkuPmUid"
                            className=""
                            label={<span>Payment Mode</span>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Payment Mode',
                                },
                            ]}
                        >
                            <Select
                                className=""
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                options={
                                    paymentModes?.length > 0 &&
                                    paymentModes?.map((item) => ({
                                        value: item?.pmUid,
                                        label: item?.pmName,
                                    }))
                                }
                                onChange={(value) => {
                                    const selectedItem = paymentModes?.find((item) => item?.pmUid === value);
                                    setPaymentModeName(selectedItem?.pmName || '');
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLocaleLowerCase() ?? '').includes(input?.toLocaleLowerCase())}
                                onDropdownVisibleChange={() => {
                                    fetchPaymentModesData();
                                }}
                                dropdownRender={(menu) => (
                                    <>
                                        {dropLoading && (
                                            <div
                                                style={{
                                                    padding: '8px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Spin className="sms-spin" />
                                            </div>
                                        )}
                                        {menu}
                                    </>
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            name="blkuWalUid"
                            className=""
                            label={<span>Payment Source</span>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select payment source',
                                },
                            ]}
                        >
                            <Select
                                className=""
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                options={
                                    subwalletData?.length > 0 &&
                                    subwalletData?.map((item) => ({
                                        value: item?.walUid,
                                        label: item?.walName,
                                    }))
                                }
                                onChange={(value) => {
                                    const selectedItem = subwalletData?.find((item) => item?.walUid === value);
                                    setPaymentSourceName(selectedItem?.walName || '');
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLocaleLowerCase() ?? '').includes(input?.toLocaleLowerCase())}
                                onDropdownVisibleChange={() => {
                                    fetchSubWalletData();
                                }}
                                dropdownRender={(menu) => (
                                    <>
                                        {dropLoading && (
                                            <div
                                                style={{
                                                    padding: '8px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Spin className="sms-spin" />
                                            </div>
                                        )}
                                        {menu}
                                    </>
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            name="blkPaymentAmount"
                            label="Amount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter amount',
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                placeholder="Enter amount"
                                className="input flex"
                            />
                        </Form.Item>
                        <Form.Item
                            name="blkIdNumber"
                            label="National ID"
                            rules={[
                                {
                                    required: false,
                                    message: 'Recepient National ID is required',
                                },
                                {
                                    validator(_, value) {
                                        if (value && value.toString().length < 6) {
                                            return Promise.reject('National ID must be at least 6 characters long');
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputNumber min={0} parser={(value) => value?.replace(/\$\s?|(,*)/g, '')} className="input flex" />
                        </Form.Item>
                        </div>

                        <div className="flex items-center lg:gap-x-5">
                        <Form.Item
                            name="blkBnkUid"
                            className="w-full"
                            label={<span>Bank</span>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Bank',
                                },
                            ]}
                        >
                            <Select
                                className=""
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                options={
                                    banks?.length > 0 &&
                                    banks?.map((item) => ({
                                        value: item?.bnkUid,
                                        label: item?.bnkName,
                                    }))
                                }
                                onChange={(value) => {
                                    const selectedItem = banks?.find((item) => item?.bnkUid === value);
                                    setBankName(selectedItem?.bnkName || '');
                                    setBankCode(selectedItem?.bnkNo || '');
                                }} 
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLocaleLowerCase() ?? '').includes(input?.toLocaleLowerCase())}
                                onDropdownVisibleChange={() => {
                                    fetchBanksData();
                                }}
                                dropdownRender={(menu) => (
                                    <>
                                        {dropLoading && (
                                            <div
                                                style={{
                                                    padding: '8px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Spin className="sms-spin" />
                                            </div>
                                        )}
                                        {menu}
                                    </>
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                        className='w-full'
                            name="blkBeneficiaryAccount"
                            label="Account Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Account Number',
                                },
                            ]}
                        >
                            <Input
                                onChange={(e) => {
                                    setAccntNumber(e.target.value);
                                }} 
                                className="input"
                            />
                        </Form.Item>

                        <div className="w-[100px]">
                <button 
                  type="button"
                  className="cstm-btn-2 !rounded-[10px]"
                  disabled={saving}
                  onClick={handleValidValidate}
                >
                  {saving ? <Spin /> : "Validate"}
                </button>
              </div> 
              </div> 
                        <Form.Item
                            name="blkBeneficiaryDetails"
                            label="Account Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Account Name',
                                },
                            ]}
                        >
                            <Input disabled={saving} className="input" />
                        </Form.Item>
                  
                    <Form.Item
                        name="blkPaymentReason"
                        label="Reason for transfer"
                        rules={[
                            {
                                required: true,
                                message: 'Enter reason for transfer',
                            },
                        ]}
                    >
                        <TextArea rows={4} placeholder="Enter reason for transfer" className="input-textarea" />
                    </Form.Item> 
                    <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
                        <div className="justify-start"></div>
                        <div className="justify-end flex items-center gap-x-2">
                            <div className="w-[200px]">
                                <button key="back" type="button" onClick={handleCancelSingle} className="cstm-btn">
                                    Cancel
                                </button>
                            </div>

                            <div className="w-[200px]">
                                <button key="submit" type="submit" className="cstm-btn-2" disabled={saving}>
                                    {saving ? <Spin /> : 'Next'}
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>
            <VerificationModal
                handleFetchData={handleFetchData}
                open={openVerification}
                setopenVerification={setopenVerification}
                handleCancel={handleCancelVerification}
                handleCancelSingle={handleCancel}
            />
        </>
    );
};
export default BankPaymentModal;
