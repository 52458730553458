import { Breadcrumb, Descriptions, Form, Input, message, Skeleton, Spin } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { capitalize, dateForHumans, formatMoney } from '../../utils';
import { fetchMainWallet, fetchSandbox } from '../../features/fetch/fetchSlice';
import BreadCrumb from '../../layout/BreadCrumb';
import { save } from '../../features/save/saveSlice';

function SandBox() {
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sandData } = useSelector((state) => state.fetch);
    const { mainWalletData, loading } = useSelector((state) => state.fetch);
    const { saving } = useSelector((state) => state.save);

    const handleCopyToClipboard = (text) => {
        navigator?.clipboard
            ?.writeText(text)
            ?.then(() => {
                message.success('Copied to clipboard!');
            })
            .catch((err) => {
                message.error('Failed to copy!');
            });
    };
    const onFinish = async (data) => {
        const res = await dispatch(
            save({
                walUId: mainWalletData?.walUId,
                walCallBackUrl: data?.walCallBackUrl,
                url: `api/v1/wallet/url`,
            })
        );
        if (res?.payload?.success) {
            await toast.success(res?.payload?.messages?.message);
            await fetchMainWalletData();
        } else {
            toast.error(res?.payload?.messages?.message);
        }
    };

    const items = [
        {
            key: '1',
            label: 'Wallet Name',
            children: <div>{mainWalletData?.walName ?? 'N/A'}</div>,
        },
        {
            key: '1',
            label: 'Wallet Amount',
            children: <div>{formatMoney(mainWalletData?.walAmount)}</div>,
        },

        {
            key: '6',
            label: 'Created Date',
            children: <div>{dateForHumans(mainWalletData?.walCreatedDate) ?? 'N/A'}</div>,
        },
        {
            key: '8',
            label: 'Endpoint',
            children: (
                <div className="w-full">
                    {mainWalletData?.walCallBackUrl ? (
                        <div>{mainWalletData?.walCallBackUrl}</div>
                    ) : (
                        <div className="lg:w-[50%] w-full">
                            <Form
                                layout="vertical"
                                ref={formRef}
                                name="control-ref"
                                onFinish={onFinish}
                                className=" "
                                style={{
                                    maxWidth: '100%',
                                }}
                                form={form}
                                initialValues={mainWalletData}
                            >
                                <Form.Item
                                    name="walCallBackUrl"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please add URL',
                                        },
                                        {
                                            type: 'url',
                                            message: 'Please enter a valid URL',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Provide url after testing it" className="input" />
                                    </Form.Item>
                                <div className={`w-[150px] ${mainWalletData?.walCallBackUrl ? 'hidden' : ''}`}>
                                    <button type="submit" className="cstm-btn-2">
                                        {saving ? <Spin /> : 'Submit'}
                                    </button>
                                </div>
                            </Form>
                        </div>
                    )}
                </div>
            ),
        },
    ];

    const items2 = [
        {
            key: '1',
            label: 'Curl',
            children: (
                <div>
                    <pre style={{ whiteSpace: 'pre-wrap' }}>{sandData?.curl ?? 'N/A'}</pre>
                    {sandData?.curl && (
                        <button className="ml-[10px] cursor-pointer text-[#F18114] font-[600] text-[1.2rem]" onClick={() => handleCopyToClipboard(sandData?.curl)}>
                            Copy
                        </button>
                    )}
                </div>
            ),
        },
    ];

    const formatJson = (jsonString) => {
        try {
            const jsonObject = JSON?.parse(jsonString);
            return JSON?.stringify(jsonObject, null, 2);
        } catch (error) {
            return jsonString;
        }
    };

    const items3 = [
        {
            key: '1',
            label: 'Response',
            children: (
                <div>
                    <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{sandData?.resp ? formatJson(sandData?.resp) : 'N/A'}</pre>
                    {sandData?.resp && (
                        <button className="ml-[10px] cursor-pointer text-[#F18114] font-[600] text-[1.2rem]" onClick={() => handleCopyToClipboard(formatJson(sandData?.resp))}>
                            Copy
                        </button>
                    )}
                </div>
            ),
        },
    ];

    const breadList = [
        {
            title: (
                <div className="flex items-center gap-[.25rem]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_627_13942)">
                            <path
                                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                                fill="black"
                                fillOpacity="0.45"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_627_13942">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>Home</span>
                </div>
            ),
            href: '/#/home',
        },
        {
            title: <div className="text-[#FFB600] text-[16px]">Sandbox</div>,
        },
    ];

    async function fetchMainWalletData() {
        dispatch(fetchMainWallet());
    }

    async function fetchSandboxData() {
        dispatch(
            fetchSandbox({
                walUId: mainWalletData?.walUId,
            })
        );
    }

    useEffect(()=>{
        fetchMainWalletData()
    },[])
    useEffect(() => {
        if (mainWalletData?.walUId) {
            fetchSandboxData();
        }
    }, [mainWalletData?.walUId]);
   

    return (
        <div className="w-full h-full overflow-y-scroll">
            <div className="flex white_card ">
                <BreadCrumb breadList={breadList} header={'SandBox'} info={'Use the sandbox app to build and test your Urls.'} />
            </div>

            <div className="mt-[1.25rem] flex flex-shrink flex-wrap w-full h-full gap-y-[1rem] white_card !bg-[inherit]">
                {loading ? (
                    <Skeleton />
                ) : (
                    <>
                        <Descriptions column={1} colon={false} title="Api Information" items={items} />

                        <Descriptions column={1} colon={false} title="Curl Information" items={items2} />

                        <Descriptions column={1} colon={false} title="Response Information" items={items3} />
                    </>
                )}
            </div>
        </div>
    );
}

export default SandBox;
