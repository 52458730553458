import { Descriptions, Modal, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OtpModal from './OtpModal';
import { fetchPayments } from '../../../../features/fetch/fetchSlice';
import { capitalize, dateForHumans, formatDateTime, numberWithCommas, timeAmPm } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../layout/BreadCrumb';
import toast from 'react-hot-toast';
import { save } from '../../../../features/save/saveSlice';

function VerificationTable() {
    const { singlePaymentObj } = useSelector((state) => state.obj);
    const { saving, bulkDetails ,bulPaymentHeader} = useSelector((state) => state.save);
    const { loading, paymentsCount, walletStatementData, payments } = useSelector((state) => state.fetch);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const [openOtp, setopenOtp] = useState(false);
    // async function handleCancelOtp() {
    //     await setopenOtp(false);
    //     await setopenVerification(true);
    // }

    // async function handleOpenOtp() {
    //     await setopenOtp(true);
    //     await setopenVerification(false);
    // }

    const handleCancel = async () => {
        await navigate('/bulk-payment');
    };
    console.log('bulkDetails', bulkDetails);
    const columns = [
        {
            title: 'Created Date',
            dataIndex: 'blkdCreatedDate',
            render: (item) => {
                return <div>{dateForHumans(item)}</div>;
            },
            sorter: (a, b) => a.blkdCreatedDate - b.blkdCreatedDate,
        },
        {
            title: 'Created Time',
            dataIndex: 'blkdCreatedTime',
            render: (item) => {
                return <div>{timeAmPm(item)}</div>;
            },
            sorter: (a, b) => a.blkdCreatedTime - b.blkdCreatedTime,
        },

        {
            title: 'Benefitiary Acccount',
            dataIndex: 'blkdBeneficiaryAccount',
            sorter: (a, b) => a.blkdBeneficiaryAccount.localeCompare(b.blkdBeneficiaryAccount),
        },

        {
            title: 'Benefitiary Bank',
            dataIndex: 'blkdBeneficiaryBank',
            sorter: (a, b) => a.blkdBeneficiaryBank.localeCompare(b.blkdBeneficiaryBank),
        },
        {
            title: 'Payment Reason',
            dataIndex: 'blkdPaymentReason',
            sorter: (a, b) => a.blkdPaymentReason.localeCompare(b.blkdPaymentReason),
        },
        {
            title: 'Mobile Number',
            dataIndex: 'blkdReceiverMobileNo',
            sorter: (a, b) => a.blkdReceiverMobileNo.localeCompare(b.blkdReceiverMobileNo),
        },
        
        {
            title: 'Benefitiary Name',
            dataIndex: 'blkdBeneficiaryDetails',
            sorter: (a, b) => a.blkdBeneficiaryDetails.localeCompare(b.blkdBeneficiaryDetails),
        },
        {
            title: 'Receiver Name',
            dataIndex: 'blkdReceiverName',
            sorter: (a, b) => a.blkdReceiverName.localeCompare(b.blkdReceiverName),
        },

        {
            title: 'Thread Time',
            dataIndex: 'blkdThreadTime',
            sorter: (a, b) => a.blkdThreadTime.localeCompare(b.blkdThreadTime),
            render: (item) => {
                return <div>{formatDateTime(item)}</div>;
            },
        },
        {
            title: 'Validition Status',
            dataIndex: 'blkdRemoteStatus',
            render: (item) => {
                return <div className={`${item === 'FAILURE' ? 'bg-[#FF4D4F]':item === 'INVALID' ? 'bg-[#FFA500]': item === 'Timed out at agent' ? 'bg-[#FF8C00]': item === 'SUCCESS' ? 'bg-darkGreen':'bg-[#1890FF]'}  text-[#FFf]  inline-block rounded-[5px] px-2 py-1 w-auto`}>{item}</div>;
            },
        },
    ];
 
    const onFinish = async (data) => {
        const res = await dispatch( 
            save({ 
                url: `bulk-payment-details/saveBulkPayments?bulkUploadId=${bulPaymentHeader?.blkudUid}`,
            })
        );
        if (res?.payload?.success) {
            await toast.success(res?.payload?.messages?.message); 
            await navigate('/bulk-payment');
        } else {
            toast.error(res?.payload?.messages?.message);
        }
    };

    const breadList = [
        {
            title: (
                <div className="flex items-center gap-[.25rem] cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_627_13942)">
                            <path
                                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                                fill="black"
                                fillOpacity="0.45"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_627_13942">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span onClick={() => navigate('/bulk-payment')}>Bulk Payment Details</span>
                </div>
            ),
        },
        {
            title: capitalize('Bulk Payments Verification'),
        },
    ];

    return (
        <div className='flex flex-col w-full h-full overflow-y-scroll'>
            <div className="justify-between items-center white_card">
                <BreadCrumb breadList={breadList} header={'Bulk Payments Verification'} info={'Would you like to make the following transfer?'} />
            </div>

            <div className="white_card mt-[1.25rem] flex flex-col">
                <div className="mt-[1.25rem]">
                    <Table
                        rowSelection={false}
                        className="mt-[1.31rem] w-full"
                        scroll={{
                            x: 800,
                        }}
                        rowKey={(record) => record?.blkBlkuUid}
                        columns={columns}
                        dataSource={bulkDetails}
                        loading={saving}
                        pagination={{
                            pageSize: 10,
                            showSizeChanger: false,
                        }}
                    />
                </div>

                <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
                    <div className="justify-start"></div>
                    <div className="justify-end flex items-center gap-x-2">
                        <div className="w-[200px]">
                            <button key="back" type="button" onClick={handleCancel} className="cstm-btn">
                                Upload Again
                            </button>
                        </div>

                        <div className="w-[150px]">
                            <button key="submit" type="submit" className="cstm-btn-2" onClick={onFinish} disabled={saving}>
                                {saving ? <Spin /> : 'Proceed'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* </Modal> */}
            {/* <OtpModal handleFetchData={handleFetchData} open={openOtp} handleCancel={handleCancelOtp} /> */}
        </div>
    );
}

export default VerificationTable;
