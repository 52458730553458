import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orgObj: {},
    walletObj: {},
    orgDocObj: {},
    directHeaderObj: {},
    singlePaymentObj: {},

    activeObj:{},
    
};

export const objSlice = createSlice({
    name: 'obj',
    initialState,
    reducers: {
        setOrgObj: (state, action) => {
            state.orgObj = action.payload;
        },
        setWalletObj: (state, action) => {
            state.walletObj = action.payload;
        },
        setOrgDocObj: (state, action) => {
            state.orgDocObj = action.payload;
        },
        setDirectHeaderObj: (state, action) => {
            state.directHeaderObj = action.payload;
        },
        setActiveObj: (state, action) => {
            state.activeObj = action.payload;
        },
        cleanOrgDocObj: (state) => {
            state.orgDocObj = {};
        },
        cleanWalletObj: (state) => {
            state.walletObj = {};
        },
        cleanOrgObj: (state) => {
            state.orgObj = {};
        },
        clearObj: () => {
            return { ...initialState };
        },
        cleanDirectHeaderObj: (state) => {
            state.directHeaderObj = {};
        },

        setSinglePaymentObj: (state, action) => {
            state.singlePaymentObj = action.payload;
        },
        cleanSinglePaymentObj: (state) => {
            state.singlePaymentObj = {};
        },
    },
});

export default objSlice.reducer;
export const { setOrgObj, cleanOrgObj, setWalletObj, cleanWalletObj, setOrgDocObj, cleanOrgDocObj, setDirectHeaderObj, 
    cleanDirectHeaderObj, setSinglePaymentObj,cleanSinglePaymentObj,setActiveObj,clearObj } = objSlice.actions;
