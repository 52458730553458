import { Descriptions, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OtpModal from './OtpModal';
import { save } from '../../../features/save/saveSlice';
import toast from 'react-hot-toast';

function VerificationModal({ open, handleCancel, handleFetchData, setopenVerification,handleCancelSingle }) {
    const { activeObj } = useSelector((state) => state.obj);
    const { saving } = useSelector((state) => state.save);
    const dispatch  =  useDispatch()

    const [openOtp, setopenOtp] = useState(false);
    async function handleCancelOtp() {
        await setopenOtp(false);
        await setopenVerification(true);
    }

    async function handleCancelAll(){
        await setopenOtp(false);
        await setopenVerification(false)
        await handleCancelSingle()
    }

    async function handleOpenOtp() {
        await setopenOtp(true);
        await setopenVerification(false);
    }
    
console.log("activeObj",activeObj)
    const onFinish = async (data) => { 
        const {paymentSourceName,benefitiaryName,type, ...restOfData } = activeObj; 
        const res = await dispatch(
          save({
            ...restOfData,
            url: "api/v1/wallet/transer-amount",
          })
        );
        if (res?.payload?.success) {
          await toast.success(res?.payload?.messages?.message);
          await handleFetchData()
          await handleCancelAll()
        //   await handleOpenOtp();
        } else {
          toast.error(res?.payload?.messages?.message);
        }
    };

   

    const items = [ 
        {
            key: '1',
            label: 'Payment Type',
            children: activeObj?.type,
        },
        {
            key: '0',
            label: 'Payment Source',
            children: activeObj?.paymentSourceName,
        },
        {
            key: '2',
            label: 'Beneficiary Account',
            children: activeObj?.benefitiaryName,
        },
        {
            key: '3',
            label: 'Payment Amount',
            children: activeObj?.amount,
        }, 
        {
            key: '5',
            label: 'Payment Reason',
            children: activeObj?.reason,
        },
        

        
    ];
 
    return (
        <>
            <Modal className="modal-btn mod-height mod-footer" title={`Verification`} open={open} onOk={onFinish} onCancel={handleCancel} width={850} maskClosable={false}>
                <p className="text-[15px] text-[#000] font-[600] mb-8 font-dmSans">Would you like to make the following transfer?</p>

                <div className="mt-[1.25rem]"> 
                        <Descriptions column={1} colon={false} title="Funds transfer?" items={items} /> 
                </div>

                <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
                    <div className="justify-start"></div>
                    <div className="justify-end flex items-center gap-x-2">
                        <div className="w-[150px]">
                            <button key="back" type="button" onClick={handleCancel} className="cstm-btn">
                                Previous
                            </button>
                        </div>

                        <div className="w-[150px]">
                            <button key="submit" type="submit" className="cstm-btn-2" onClick={onFinish} disabled={saving}>
                                {saving ? <Spin /> : 'Next'}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <OtpModal handleFetchData={handleFetchData} open={openOtp} handleCancel={handleCancelOtp} handleCancelAll={handleCancelAll}/>
        </>
    );
}

export default VerificationModal;
