import React from 'react';
import logo from '../../../assets/img/Main logo.png';
import { Progress } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cleanOrgDocObj, cleanOrgObj } from '../../../features/obj/objSlice';

function HeaderKyc({percent}) {
    const dispatch = useDispatch()
    const navigate =  useNavigate()

    const handleLogin= async()=>{
        await dispatch(cleanOrgDocObj());
        await dispatch(cleanOrgObj());
        await navigate('/login')
    }
    return (
        <>
            <div className=" px-[2.06rem] py-[1.88rem] w-full">
                <img src={logo} className="w-[7.125rem] h-[1.6875rem] object-cover" alt="image2"
                //  onClick={handleLogin}
                 />
            </div>
            <div className="">
                <Progress percent={percent} />
            </div>
        </>
    );
}

export default HeaderKyc;
