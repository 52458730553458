import { Dropdown, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HowToAddContactModal from "./modal/HowToAddContactModal";
import ContactsListView from "./ContactsListView";
import AddGroupContactModal from "./modal/AddGroupContactModal";
import BreadCrumb from "../../../layout/BreadCrumb";
import { capitalize } from "../../../utils";
import flash from "../../../assets/svg/Flash.svg";
import { cleanSinglePaymentObj } from "../../../features/obj/objSlice";
import GroupPaymentModal from "./modal/GroupPaymentModal";
import { fetchGroupMembers, fetchPayments } from "../../../features/fetch/fetchSlice";
import CardLoading from "../../../components/CardLoading";

export default function GroupFolderView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeObj } = useSelector((state) => state.obj);
  const { loading, groupMembers } = useSelector((state) => state.fetch);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, sethasSelected] = useState(false); 

  const [openGraduate, setopenGraduate] = useState(false);
  const [activeGradListObj, setactiveGradListObj] = useState({});
 
 async function handleOpenGraduate() {
   await setopenGraduate(true);
  }
  function handleCancelGraduate() {
    setopenGraduate(false);
  }

  const [openMpesa, setOpenMpesa] = useState(false);
  const handleOpenMpesaPayment = async () => {
    await setOpenMpesa(true);
    await dispatch(cleanSinglePaymentObj());
  };
  const handleCancelMpesaPayment = () => {
    setOpenMpesa(false);
  };

  

  const handleGraduateOption = () => {
    setopenGraduate(true);
  };

  async function handleUploadFile() {
    await navigate("/contacts/add/file-upload");
  }

  async function handleGradListObj(item) {
    // await dispatch(setGraduateListObj(item));
    // await setactiveGradListObj(item);
    // await navigate(`/contacts/folders/list/${item?.gdcTitle}`)
  }

  const settingItems = [
    {
      key: "0",
      label: (
        <button
          onClick={() =>
            navigate(`/contacts/folders/list/${activeGradListObj?.gdcTitle}`)
          }
          className="font-dmSans text-black font-[400] text-[19px] !mt-[5%]"
        >
          View list
        </button>
      ),
    },

    {
      key: "1",
      label: (
        <div
          onClick={handleUploadFile}
          className="font-dmSans text-black font-[400] text-[19px] !mt-[5%]"
        >
          Upload .csv
        </div>
      ),
    },
  ];

  const settingItemsQuickActions = [
    {
      key: "0",
      label: (
        <div
          onClick={handleOpenGraduate}
          className="font-dmSans text-[#344054] font-[500] text-[18px] !mt-[5%]"
        >
          Add contacts
        </div>
      ),
    },
    {
      key: "0",
      label: (
        <div
          onClick={handleOpenMpesaPayment}
          className="font-dmSans text-[#344054] font-[500] text-[18px] !mt-[5%]"
        >
          Initiate Direct Payment
        </div>
      ),
    },
  ];

   
  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Groups</span>
        </div>
      ),
      href: "/#/groups",
    },
    {
      title: capitalize("Group Contacts"),
    },
  ];

  async function handleFetchData() {
    await dispatch(fetchGroupMembers({
      url:'api/v1/payments/fetch-payment-group-details',
      pgdPgId:activeObj?.pgId
    }));
  }
 

  async function fetchPaymentsData() {
    dispatch(
      fetchPayments({
        url: "api/v1/payments/fetch-bulk-payments", 
        blkuEntryType: "DIRECT",
      })
    );
  }

  

  useEffect(() => {
    handleFetchData();
  }, [activeObj]);

  useEffect(() => {}, [groupMembers]); 

  if (loading) return <CardLoading />;
  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="justify-between items-center white_card">
        <BreadCrumb
          breadList={breadList}
          header={"List of Contacts"}
          info={"Manage contacts within your group"}
          btnDropdown={true}
          btnTitle="Quick Actions"
          btnSvg={flash}
          settingItemsQuickActions={settingItemsQuickActions}
        />
      </div>
      <div className="white_card mt-[1.25rem] flex flex-col">
        <div className="flex items-center justify-between w-full">
          <div>
            <h3 className="dash-title font-[700] text-[24px] text-black1">
              {activeObj?.pgName}
            </h3>
          </div>
        </div>

        <ContactsListView handleGraduateOption={handleGraduateOption} />
      </div>
      {/* <AddGroupContactModal
        open={open}
        handleFetchData={handleFetchData}
        handleCancel={handleCancel}
      /> */}
      <HowToAddContactModal
        open={openGraduate}
        handleCancel={handleCancelGraduate}
        uploadGraduateFile={handleUploadFile} 
        handleFetchData={handleFetchData}
      />

      <GroupPaymentModal
        handleFetchData={fetchPaymentsData}
        open={openMpesa}
        setopenSingle={setOpenMpesa}
        handleCancel={handleCancelMpesaPayment}
      />
    </div>
  );
}
