export const GenderData = [
    {
      label: "Male",
      value: "M",
    },
    {
      label: "Female",
      value: "F",
    } 
  ];


  export const IdTypeData = [
    {
      label: "PROMOTIONAL",
      value: "PROMOTIONAL",
    },
    {
      label: "TRANSACTIONAL",
      value: "TRANSACTIONAL",
    }
   
  ];

  export const instTypeData = [
    {
      label: "COMPANY",
      value: "COMPANY",
    },
    {
      label: "INDIVIDUAL",
      value: "INDIVIDUAL",
    } 
  ];
 
  export const ownershipData = [
    {
      label: "I want to share a service code with multiple users",
      value: "SHARED",
    },
    {
      label: "I want my own service code",
      value: "PRIVATE",
    } 
  ];


  export const costData = [
    {
      label: "The Customer",
      value: "CUSTOMER",
    },
    {
      label: "Me, as the owner of the service code",
      value: "ME",
    } 
  ];

  export const userData = [
    {
      label: "KAMAU",
      value: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    }, 
  ];

 
export const recurringBillsData = [
  {
    label: "Electricity",
    value: "Electricity",
  },
  {
    label: "Water",
    value: "Water",
  },
  {
    label: "Security",
    value: "Security",
  }, 
];

export const statusData = [
  {
    label: "ACCEPT",
    value: "ACCEPT",
  },
  {
    label: "REJECT",
    value: "REJECT",
  }, 
];

export const accessLevelData = [
  {
    label: "User",
    value: "USER",
  },
  {
    label: "Manager",
    value: "MANAGER",
  }, 
  {
    label: "Admin",
    value: "ADMIN",
  }, 
];

export const monthsData = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const leaveTypesData = [
  {
    label: "Annual",
    value: "AN",
  },
  {
    label: "Maternity",
    value: "MT",
  },
  {
    label: "Paternity",
    value: "PT",
  },
  {
    label: "Sick Leave",
    value: "SI",
  },
  {
    label: "Sick Off",
    value: "SO",
  },
  {
    label: "Compassionate Leave",
    value: "CP",
  },
  {
    label: "Home Leave",
    value: "HM",
  },
  {
    label: "Special Leave",
    value: "SP",
  },
  {
    label: "Sabbatical Leave",
    value: "SB",
  },
  {
    label: "Study Leave",
    value: "ST",
  },
  {
    label: "Leave of Absence",
    value: "LA",
  },
];


export const YesNoData = [
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  } 
];

export const dutyData = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  } 
];

export const paymentMethodsData = [
  {
    label: "Bank Payment",
    value: "BANK",
  },
  {
    label: "Mobile Payment",
    value: "MPESA",
  } 
];
export const attendanceTypeData = [
  {
    label: "CHECK_IN",
    value: "CHECK_IN",
  },
  {
    label: "CHECK_OUT",
    value: "CHECK_OUT",
  },
  {
    label: "OVERTIME_IN",
    value: "OVERTIME_IN",
  },
  {
    label: "OVERTIME_OUT",
    value: "OVERTIME_OUT",
  }   
];

export const typeData = [
  {
    label: "NORMALDAY",
    value: "NORMALDAY",
  },
  {
    label: "WEEKEND",
    value: "WEEKEND",
  }, 
];
 

export const cityData = [
  { label: "Nairobi", value: "Nairobi" },
  { label: "Mombasa", value: "Mombasa" },
  { label: "Kisumu", value: "Kisumu" },
  { label: "Nakuru", value: "Nakuru" },
  { label: "Eldoret", value: "Eldoret" },
  { label: "Thika", value: "Thika" },
  { label: "Malindi", value: "Malindi" },
  { label: "Kitale", value: "Kitale" },
  { label: "Garissa", value: "Garissa" },
  { label: "Nyeri", value: "Nyeri" },
  { label: "Machakos", value: "Machakos" },
  { label: "Meru", value: "Meru" },
  { label: "Embu", value: "Embu" },
  { label: "Kericho", value: "Kericho" },
  { label: "Nanyuki", value: "Nanyuki" },
  { label: "Naivasha", value: "Naivasha" },
  { label: "Kakamega", value: "Kakamega" },
  { label: "Bungoma", value: "Bungoma" },
  { label: "Migori", value: "Migori" },
  { label: "Kisii", value: "Kisii" },
  { label: "Nyahururu", value: "Nyahururu" },
  { label: "Lamu", value: "Lamu" },
  { label: "Isiolo", value: "Isiolo" },
  { label: "Marsabit", value: "Marsabit" },
];

 
export const maritalStatusData = [
  {
    label: "SINGLE",
    value: "S",
  },
  {
    label: "MARRIED",
    value: "M",
  }
   
];
export const walletTransferType = [
	{
		label: 'Wallet',
		value: 'WALLET',
	},
	{
		label: 'Settlement Account',
		value: 'SETTLE',
	},
];