import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;
const initialState = {
    authLoading: false,
    user: {},
    isActive: false,
    refresh_token: '',
    authData: {},
    saving: false,

    forgotAcc: {},
    singleUser: {},
    loginInfo: {},

    userOut: {},
};

export const loginIntial = createAsyncThunk('authSlice/loginIntial', async (data) => {
    const res = await axios.post(`${url}/auth/login`, data).then((res) => res.data);
    return res;
});

export const login = createAsyncThunk('authSlice/login', async (data) => {
    const res = await axios.post(`${url}/auth/login`, data).then((res) => res.data);
    return res;
});

export const save = createAsyncThunk('user/save', async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
        const response = await axios.post(`${url}/${saveUrl}`, data);
        if (!response.data.success) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const resetPassword = createAsyncThunk('user/resetPassword', async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
        const response = await axios.post(`${url}/${saveUrl}`, data);
        if (!response.data.success) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const saveOtp = createAsyncThunk('saveSlice/saveOtp', async (data) => {
    let saveUrl = data.url;
    delete data.url;
    const res = await axios.post(`${url}/${saveUrl}`, data).then((res) => res?.data);
    return res;
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.user = {};
            state.isActive = false;
            state.refresh_token = '';
            state.chooseAccount = false;
            state.authData = {};
            state.userOut = {};
        },
        handleLoginSession: (state, action) => {
            state.isActive = action.payload;
        },
        handleUserRefreshToken: (state, action) => {
            state.refresh_token = action.payload;
        },

        handleUser: (state, action) => {
            state.user = action.payload;
        },
        handleAuthData: (state, action) => {
            state.authData = { ...state.authData, ...action.payload };
        },

        handleUserOut: (state, action) => {
            state.userOut = action.payload;
        },

        cleanForgotAcc: (state, action) => {
            state.forgotAcc = initialState.forgotAcc;
        },
        cleanSingleUser: (state) => {
            state.singleUser = initialState.singleUser;
        },
        cleanAuthLoading: (state) => {
            state.authLoading = initialState.authLoading;
        },
        clearAuthObj: () => {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(loginIntial.pending, (state) => {
                state.authLoading = true;
            })
            .addCase(loginIntial.fulfilled, (state, action) => {
                state.authLoading = false;
                state.loginInfo = action.payload?.data?.result;
            })
            .addCase(loginIntial.rejected, (state) => {
                state.authLoading = false;
                state.loginInfo = {};
            })

            .addCase(login.pending, (state) => {
                state.authLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.authLoading = false;
            })
            .addCase(login.rejected, (state) => {
                state.authLoading = false;
            })

            .addCase(save.pending, (state) => {
                state.saving = true;
            })
            .addCase(save.fulfilled, (state) => {
                state.saving = false;
            })
            .addCase(save.rejected, (state) => {
                state.saving = false;
            })

            .addCase(resetPassword.pending, (state) => {
                state.saving = true;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.saving = false;
                state.forgotAcc = action.payload?.data?.result;
            })
            .addCase(resetPassword.rejected, (state) => {
                state.saving = false;
            })

            .addCase(saveOtp.pending, (state) => {
                state.saving = true;
            })
            .addCase(saveOtp.fulfilled, (state, action) => {
                state.saving = false;
            })
            .addCase(saveOtp.rejected, (state) => {
                state.saving = false;
            });
    },
});

export default authSlice.reducer;
export const { logout, setAuth, handleUserRefreshToken, handleChooseAccount, handleLoginSession, handleUser, handleAuthData, cleanSingleUser, cleanForgotAcc, handleUserOut, cleanAuthLoading,clearAuthObj } =
    authSlice.actions;
