import { Descriptions, Form, Input, Modal, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addSpaces, obfuscateEmail } from '../../../../utils';
import { save } from '../../../../features/save/saveSlice';
import toast from 'react-hot-toast';

function OtpModal({ open, handleCancel, handleFetchData, handleCancelAll }) {
    const { singlePaymentObj } = useSelector((state) => state.obj);
    const { saving } = useSelector((state) => state.save);
    const { user } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const formRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({});
    const [isInputEmpty, setIsInputEmpty] = useState(true);

    const [counter, setCounter] = useState({ minutes: 0, seconds: 59 });
    const [activeResend, setActiveResend] = useState(false);

    const resendOTPEmail = async () => {
        // const res = await dispatch(
        //   saveOtp({
        //     url: `auth/send-email-otp/${forgotAcc?.usrId}`,
        //   })
        // );
        // if (res?.payload?.success) {
        //   toast.success(res.payload?.messages?.message);
        // } else {
        //   toast.error(res.payload?.messages?.message);
        // }
    };

    const handleBack = async () => {
        await navigate('/forgot-password');
    };

    const onFinish = async (data) => { 
        const res = await dispatch(
            save({
                usrSecret: data?.usrSecret,
                url: 'api/v1/payments/verify-payment-otp',
            })
        );
        if (res?.payload?.success) {
            await toast.success(res.payload?.messages?.message);
            const { paymentSourceName, bankName, paymentModeName, ...restOfData } = singlePaymentObj;
            const res1 = await dispatch(
                save({
                    ...restOfData,
                    url: 'api/v1/payments/save-direct-payment',
                })
            );
            if (res1?.payload?.success) {
                await toast.success(res.payload?.messages?.message);
                await handleCancelAll(); 
                await handleFetchData();
            } else {
                toast.error(res.payload?.messages?.message);
            }

            setIsInputEmpty(true);
            form.resetFields();
        } else {
            toast.error(res.payload?.messages?.message);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setIsInputEmpty(!value);
        setFormData((prevData) => ({
            ...prevData,
            usrFirstName: value,
        }));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (counter.minutes === 0 && counter.seconds === 0) {
                clearInterval(interval);
            } else {
                setCounter((prevCounter) => {
                    if (prevCounter.seconds === 0) {
                        return { minutes: prevCounter.minutes - 1, seconds: 59 };
                    } else {
                        return { ...prevCounter, seconds: prevCounter.seconds - 1 };
                    }
                });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [counter]);
    return (
        <>
            <Modal className="modal-btn mod-height mod-footer" title={`Verification`} open={open} onOk={onFinish} onCancel={handleCancel} width={750} maskClosable={false}>
                <div className="w-full h-full flex flex-col">
                    <Form
                        layout="vertical"
                        ref={formRef}
                        name="control-ref"
                        onFinish={onFinish}
                        style={{
                            maxWidth: '100%',
                        }}
                        className="lg:px-20 px-3"
                        form={form}
                    >
                        <div className="grid grid-cols-1 mt-10">
                            <Form.Item
                                name="usrSecret"
                                label={<span>Enter OTP sent to {user?.usrMobileNumber ? addSpaces(user?.usrMobileNumber) : ''} </span>}
                                className="login-form-item "
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add otp',
                                    },
                                ]}
                            >
                                <Input onChange={handleInputChange} className="input" />
                            </Form.Item>
                        </div>

                        <div className="justify-center flex items-center gap-x-2 mt-[56px] mb-[48px]">
                            <div className="w-[150px]">
                                <button key="back" type="button" onClick={handleCancel} className="cstm-btn">
                                    Previous
                                </button>
                            </div>

                            <div className="w-[150px]">
                                <button
                                    type="submit"
                                    key="submit"
                                    className={`cstm-btn-2 ${isInputEmpty ? '!bg-[#D1D5DB]' : 'inherit'} ${isInputEmpty ? 'cursor-not-allowed' : 'inherit'}`}
                                    disabled={isInputEmpty || saving}
                                >
                                    {saving ? <Spin /> : 'Transfer'}
                                </button>
                            </div>
                        </div>

                        {/* <div className="mt-[66px] flex items-center justify-center typography_p_2 gap-x-1">
                            Didn’t receive OTP?
                            {counter.minutes === 0 && counter.seconds === 0 ? (
                                <span
                                    className="font-[600] typography_p_2 !text-[#0057E3] cursor-pointer"
                                    onClick={resendOTPEmail}
                                    disabled={counter.minutes > 0 && counter.seconds > 0}
                                    type="primary"
                                >
                                    Resend
                                </span>
                            ) : (
                                <span className="font-[600] typography_p_2">
                                    {' '}
                                    Resend in {counter.minutes.toString().padStart(2, '0')}:{counter.seconds.toString().padStart(2, '0')}
                                </span>
                            )}
                        </div> */}
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default OtpModal;
