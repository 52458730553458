import React from "react";
import userImg from "../../../assets/img/user.png";
import moment from "moment/moment";

function UserCardInfo({ activeObj }) {
  return (
    <div className="flex items-start gap-[1.25rem] w-full h-[16.75rem] bg-[#F2F4F7] px-[1.5rem] py-[2.75rem]">
      <div className="w-[9.125rem] h-[9.125rem]">
        <img
          src={userImg}
          alt="avatar"
          className="w-full h-full rounded-full object-cover"
        />
      </div>
      <div className="flex flex-col">
        <span className="heading_1">
          {" "}
          {activeObj?.custFirstname} {activeObj?.custLastname}
        </span>
        <span className="label_3 !text-[#64748B] my-[.25rem]">
          ({activeObj?.role})
        </span>
        <span className="label_3 !text-[#64748B] ">
          Created on {moment(activeObj?.custCreatedDate).format("Do MMM YYYY")}
        </span>

        <div className="flex items-center mt-[1.25rem] w-full">
          <span className="member_info_text w-[6rem]">ID No</span>
          <span className="member_info_text !font-normal">
            {activeObj?.custNationalId}
          </span>
        </div>

        <div className="flex items-center my-[.5rem] w-full">
          <span className="member_info_text w-[6rem]">Phone No</span>
          <span className="member_info_text !font-normal">
            {activeObj?.custMobileNumber}
          </span>
        </div>

        <div className="flex items-center w-full">
          <span className="member_info_text w-[6rem]">Email</span>
          <span className="member_info_text !font-normal">
            {activeObj?.custEmail}
          </span>
        </div>
      </div>
    </div>
  );
}

export default UserCardInfo;
