import React, { useState } from 'react';
import { capitalize } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../../../layout/BreadCrumb';
import { Breadcrumb, Tabs } from 'antd';
import UsersAdd from '../modal/UsersAdd'; 
import UserCardInfo from './UserCardInfo';
import Permissions from './Permissions';
import MyWallet from '../../wallets/MyWallet';
import toast from 'react-hot-toast';
import { setRefetch, setRefetchKey } from '../../../features/global/globalSlice';
import KycDocuments from './KycDocuments';
import CustomerWallets from './CustomerWallets';

function UserAddList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { activeObj } = useSelector((state) => state.obj);

    const [active, setactive] = useState('1'); 
    async function refetching(activeKey) {
        if (activeObj?.custId) {
            await setactive(activeKey);
            await dispatch(setRefetch());
            await dispatch(setRefetchKey(activeKey));
        } else {
            toast.error('You should have a customer');
        }
    }

    const tabConfigurations = [
        {
            label: (
                <span className="flex items-center">
                    <span className=" ">Customer Details</span>
                </span>
            ),
            key: '1',
            children: <UsersAdd setactive={setactive}/>,
        },

        {
            label: (
                <span className="flex items-center">
                    <span className=" ">KYC Documents</span>
                </span>
            ),
            key: '2',
            children: <KycDocuments />,
        },
        {
            label: (
                <span className="flex items-center">
                    <span className=" ">Permissions</span>
                </span>
            ),
            key: '3',
            children: <Permissions />,
        },
        {
            label: (
                <span className="flex items-center">
                    <span className=" ">Wallets</span>
                </span>
            ),
            key: '4',
            children: <CustomerWallets  />,
        },
    ];

 

    const filteredTabConfigurations =  !activeObj?.custId  ? tabConfigurations.filter((tab) => tab.key !== '3' && tab.key !== '4') : tabConfigurations;

    const breadList = [
        {
            title: (
                <div className="flex items-center gap-[.25rem] cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_627_13942)">
                            <path
                                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                                fill="black"
                                fillOpacity="0.45"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_627_13942">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span onClick={() => navigate('/users')}>Customers list</span>
                </div>
            ),
        },
        {
            title: capitalize('Customer'),
        },
    ];

    return (
        <div className='w-full h-full overflow-y-scroll'> 
            {activeObj?.custId ? ( 
                <div className='mt-[1.25rem]'>
                     <div className=" font-dmSans text-[18px] mb-2">
                <Breadcrumb
                    items={[
                        {
                            title: (
                                <span className="font-dmSans cursor-pointer " onClick={() => navigate('/users')}>
                                    Customers List
                                </span>
                            ),
                        },
                        {
                            title: <span className="text-lighGold font-dmSans !text-[16px]">Customer Details</span>,
                        },
                    ]}
                />
            </div>
                    <UserCardInfo activeObj={activeObj} />
                </div>
            ) : (
                <div className="justify-between items-center white_card">
                    <BreadCrumb breadList={breadList} header={'Add Customer'} info={'Manage your customer details here'} />
                </div>
            )}

            <div className="mt-[1.25rem] lg:px-10 px-3">
                <Tabs items={filteredTabConfigurations} defaultActiveKey="1" activeKey={active} onChange={refetching} className="activity-tab-wrap activity-tab activity-bar" />
            </div>
        </div>
    );
}

export default UserAddList;
