import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../instance";
import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URL;
const transUrl = process.env.REACT_APP_API_TRANS_URL;

const initialState = {
  loading: false,
  dropLoading: false,
  mainWalletData: {},
  subwalletData: [],
  sandData: [],
  userStatus: [],
  myUsersData: [],
  usersCount: 0,
  rolesData: [],
  walletStatementData: [],
  walletStatementCount: 0,
  attachmentSetupCount: 0,
  attachmentSetup: [],

  c2BTransactionData: [],
  c2BTransactionCount: 0,

  paymentModes: [],
  banks: [],
  customerWallets: [],

  paymentsCount: 0,
  paymentDetailsCount: 0,
  paymentDetails: [],
  payments: [],
  customerStatus: [],
  attRequirements: [],
  attLevel2: [],
  attLevel3: [],
  paymentGroups: [],
  paymentGroupsCount: 0,

  groupMembers: [],
  groupMembersCount: 0,

  fundsTransferCount: 0,
  fundsTransfer: [],
  disbursementWallet: {},

  apprvNames: [], 

  appvConfig:[],
  appvConfigCount:0,

  processApprs:[],
  processApprsCount:0,
  
};

export const fetchMainWallet = createAsyncThunk(
  "dropdwonSlice/fetchMainWallet",
  async (data) => {
    const res = await axiosInstance
      .get(`${url}/api/v1/wallet/get-main-wallet`)
      .then((res) => res.data.data.result);
    return res;
  }
);

export const fetchSandbox = createAsyncThunk(
  "sandboxSlice/fetchSandbox",
  async (data) => {
    const res = await axiosInstance
      .get(`${url}/api/v1/sandbox/url-validation?walUId=${data?.walUId}`)
      .then((res) => res.data?.data?.result);
    return res;
  }
);

export const fetchSubwallet = createAsyncThunk(
  "save/fetchSubwallet",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data.data.result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSubwalletDropdown = createAsyncThunk(
  "save/fetchSubwalletDropdown",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data.data.result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUsersStatus = createAsyncThunk(
  "filterSlice/fetchUsersStatus",
  async (data) => {
    const res = await axiosInstance
      .get(`${url}/api/v2/users/distinct-status`)
      .then((res) => res.data?.data?.result);
    return res;
  }
);

export const fetchMyUsers = createAsyncThunk(
  "saveSlice/fetch/fetchMyUsers",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRoles = createAsyncThunk("user/fetchRoles", async (data) => {
  const res = await axiosInstance
    .get(`${url}/api/v2/users/roles`)
    .then((res) => res.data?.data?.result);
  return res;
});

export const fetchWalletStatement = createAsyncThunk(
  "saveSlice/fetch/fetchWalletStatement",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchC2btransactions = createAsyncThunk(
  "saveSlice/fetch/fetchC2btransactions",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAttachmentSetup = createAsyncThunk(
  "saveSlice/fetch/fetchAttachmentSetup",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPaymentModes = createAsyncThunk(
  "saveSlice/fetch/fetchPaymentModes",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBanks = createAsyncThunk(
  "saveSlice/fetch/fetchBanks",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPayments = createAsyncThunk(
  "save/fetchPayments",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPaymentDetails = createAsyncThunk(
  "save/fetchPaymentDetails",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomerStatus = createAsyncThunk(
  "save/fetchCustomerStatus",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchattachmentRequirements = createAsyncThunk(
  "save/fetchattachmentRequirements",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchattachmentLevel2 = createAsyncThunk(
  "save/fetchattachmentLevel2",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchattachmentLevel3 = createAsyncThunk(
  "save/fetchattachmentLevel3",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomerWallets = createAsyncThunk(
  "save/fetchCustomerWallets",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPaymentGroups = createAsyncThunk(
  "save/fetchPaymentGroups",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchGroupMembers = createAsyncThunk(
  "save/fetchGroupMembers",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadMembersExcel = createAsyncThunk(
  "graduate/downloadMembersExcel",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${url}/api/v1/payments/download-payment-group-template`,
        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFundsTransfer = createAsyncThunk(
  "save/fetchFundsTransfer",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDisbursmentWallet = createAsyncThunk(
  "dropdwonSlice/fetchDisbursmentWallet",
  async (data) => {
    const res = await axiosInstance
      .get(`${url}/api/v1/wallet/get-disbursement-wallet`)
      .then((res) => res.data.data.result);
    return res;
  }
);

export const fetchApprvNames = createAsyncThunk(
    "dropdwonSlice/fetchApprvNames",
    async (data) => {
      const res = await axiosInstance
        .get(`${url}/api/v1/approvals/fetch-approval-names`)
        .then((res) => res.data.data.result);
      return res;
    }
  );
 
export const fetchApprvConfig = createAsyncThunk(
    "save/fetchApprvConfig",
    async (data, { rejectWithValue }) => {
      let saveUrl = data.url;
      delete data.url;
      try {
        const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
        if (!response.data.success) {
          return rejectWithValue(response.data);
        }
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const fetchProcessApprs = createAsyncThunk(
    "save/fetchProcessApprs",
    async (data, { rejectWithValue }) => {
      let saveUrl = data.url;
      delete data.url;
      try {
        const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
        if (!response.data.success) {
          return rejectWithValue(response.data);
        }
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

export const fetchSlice = createSlice({
  name: "fetch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchMainWallet.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMainWallet.fulfilled, (state, action) => {
        state.loading = false;
        state.mainWalletData = action.payload;
      })
      .addCase(fetchMainWallet.rejected, (state) => {
        state.loading = false;
        state.mainWalletData = {};
      })

      .addCase(fetchSandbox.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSandbox.fulfilled, (state, action) => {
        state.loading = false;
        state.sandData = action.payload;
      })
      .addCase(fetchSandbox.rejected, (state) => {
        state.loading = false;
        state.sandData = [];
      })

      .addCase(fetchSubwallet.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubwallet.fulfilled, (state, action) => {
        state.loading = false;
        state.subwalletData = action.payload;
      })
      .addCase(fetchSubwallet.rejected, (state) => {
        state.loading = false;
        state.subwalletData = [];
      })

      .addCase(fetchSubwalletDropdown.pending, (state) => {
        state.dropLoading = true;
      })
      .addCase(fetchSubwalletDropdown.fulfilled, (state, action) => {
        state.dropLoading = false;
        state.subwalletData = action.payload;
      })
      .addCase(fetchSubwalletDropdown.rejected, (state) => {
        state.dropLoading = false;
        state.subwalletData = [];
      })

      .addCase(fetchUsersStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsersStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.userStatus = action.payload;
      })
      .addCase(fetchUsersStatus.rejected, (state) => {
        state.loading = false;
        state.userStatus = [];
      })

      .addCase(fetchMyUsers.pending, (state) => {
        state.dropLoading = true;
      })
      .addCase(fetchMyUsers.fulfilled, (state, action) => {
        state.dropLoading = false;
        state.myUsersData = action.payload?.data?.result;
        state.usersCount = action.payload.total;
      })
      .addCase(fetchMyUsers.rejected, (state) => {
        state.dropLoading = false;
        state.myUsersData = [];
        state.usersCount = 0;
      })

      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.rolesData = action.payload;
      })
      .addCase(fetchRoles.rejected, (state) => {
        state.loading = false;
        state.rolesData = [];
      })

      .addCase(fetchWalletStatement.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWalletStatement.fulfilled, (state, action) => {
        state.loading = false;
        state.walletStatementData = action.payload?.data?.result;
        state.walletStatementCount = action.payload.total;
      })
      .addCase(fetchWalletStatement.rejected, (state) => {
        state.loading = false;
        state.walletStatementData = [];
        state.walletStatementCount = 0;
      })

      .addCase(fetchC2btransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchC2btransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.c2BTransactionData = action.payload?.data?.result;
        state.c2BTransactionCount = action.payload.total;
      })
      .addCase(fetchC2btransactions.rejected, (state) => {
        state.loading = false;
        state.c2BTransactionData = [];
        state.c2BTransactionCount = 0;
      })

      .addCase(fetchAttachmentSetup.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAttachmentSetup.fulfilled, (state, action) => {
        state.loading = false;
        state.attachmentSetup = action.payload?.data?.result;
        state.attachmentSetupCount = action.payload.total;
      })
      .addCase(fetchAttachmentSetup.rejected, (state) => {
        state.loading = false;
        state.attachmentSetup = [];
        state.attachmentSetupCount = 0;
      })

      .addCase(fetchPaymentModes.pending, (state) => {
        state.dropLoading = true;
      })
      .addCase(fetchPaymentModes.fulfilled, (state, action) => {
        state.dropLoading = false;
        state.paymentModes = action.payload?.data?.result;
      })
      .addCase(fetchPaymentModes.rejected, (state) => {
        state.dropLoading = false;
        state.paymentModes = [];
      })

      .addCase(fetchBanks.pending, (state) => {
        state.dropLoading = true;
      })
      .addCase(fetchBanks.fulfilled, (state, action) => {
        state.dropLoading = false;
        state.banks = action.payload?.data?.result;
      })
      .addCase(fetchBanks.rejected, (state) => {
        state.dropLoading = false;
        state.banks = [];
      })

      .addCase(fetchPayments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = action.payload?.data?.result;
        state.paymentsCount = action.payload.total;
      })
      .addCase(fetchPayments.rejected, (state) => {
        state.loading = false;
        state.payments = [];
        state.paymentsCount = 0;
      })

      .addCase(fetchPaymentDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPaymentDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentDetails = action.payload?.data?.result;
        state.paymentDetailsCount = action.payload.total;
      })
      .addCase(fetchPaymentDetails.rejected, (state) => {
        state.loading = false;
        state.paymentDetails = [];
        state.paymentDetailsCount = 0;
      })

      .addCase(fetchCustomerStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomerStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.customerStatus = action.payload?.data?.result;
      })
      .addCase(fetchCustomerStatus.rejected, (state) => {
        state.loading = false;
        state.customerStatus = [];
      })

      .addCase(fetchattachmentRequirements.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchattachmentRequirements.fulfilled, (state, action) => {
        state.loading = false;
        state.attRequirements = action.payload?.data?.result;
      })
      .addCase(fetchattachmentRequirements.rejected, (state) => {
        state.loading = false;
        state.attRequirements = [];
      })

      .addCase(fetchattachmentLevel2.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchattachmentLevel2.fulfilled, (state, action) => {
        state.loading = false;
        state.attLevel2 = action.payload?.data?.result;
      })
      .addCase(fetchattachmentLevel2.rejected, (state) => {
        state.loading = false;
        state.attLevel2 = [];
      })

      .addCase(fetchattachmentLevel3.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchattachmentLevel3.fulfilled, (state, action) => {
        state.loading = false;
        state.attLevel3 = action.payload?.data?.result;
      })
      .addCase(fetchattachmentLevel3.rejected, (state) => {
        state.loading = false;
        state.attLevel3 = [];
      })

      .addCase(fetchCustomerWallets.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomerWallets.fulfilled, (state, action) => {
        state.loading = false;
        state.customerWallets = action.payload?.data?.result;
      })
      .addCase(fetchCustomerWallets.rejected, (state) => {
        state.loading = false;
        state.customerWallets = [];
      })

      .addCase(fetchPaymentGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPaymentGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentGroups = action.payload?.data?.result;
        state.paymentGroupsCount = action.payload.total;
      })
      .addCase(fetchPaymentGroups.rejected, (state) => {
        state.loading = false;
        state.paymentGroups = [];
        state.paymentGroupsCount = 0;
      })

      .addCase(fetchGroupMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroupMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.groupMembers = action.payload?.data?.result;
        state.groupMembersCount = action.payload.total;
      })
      .addCase(fetchGroupMembers.rejected, (state) => {
        state.loading = false;
        state.groupMembers = [];
        state.groupMembersCount = 0;
      })

      .addCase(downloadMembersExcel.pending, (state) => {
        state.loading = true;
      })
      .addCase(downloadMembersExcel.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(downloadMembersExcel.rejected, (state) => {
        state.loading = false;
      })

      .addCase(fetchDisbursmentWallet.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDisbursmentWallet.fulfilled, (state, action) => {
        state.loading = false;
        state.disbursementWallet = action.payload;
      })
      .addCase(fetchDisbursmentWallet.rejected, (state) => {
        state.loading = false;
        state.disbursementWallet = {};
      })

      .addCase(fetchApprvNames.pending, (state) => {
        state.dropLoading = true;
      })
      .addCase(fetchApprvNames.fulfilled, (state, action) => {
        state.dropLoading = false;
        state.apprvNames = action.payload; 
      })
      .addCase(fetchApprvNames.rejected, (state) => {
        state.dropLoading = false;
        state.apprvNames = []; 
      })


      .addCase(fetchApprvConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchApprvConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.appvConfig = action.payload?.data?.result;
        state.appvConfigCount = action.payload.total;
      })
      .addCase(fetchApprvConfig.rejected, (state) => {
        state.loading = false;
        state.appvConfig = [];
        state.appvConfigCount = 0;
      })


      .addCase(fetchProcessApprs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProcessApprs.fulfilled, (state, action) => {
        state.loading = false;
        state.processApprs = action.payload?.data?.result;
        state.processApprsCount = action.payload.total;
      })
      .addCase(fetchProcessApprs.rejected, (state) => {
        state.loading = false;
        state.processApprs = [];
        state.processApprsCount = 0;
      })
     
   
  },
});

export default fetchSlice.reducer;
export const {} = fetchSlice.actions;
