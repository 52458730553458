import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Form, Input, Spin } from 'antd';
import { cleanAuthLoading, clearAuthObj, loginIntial } from '../../features/auth/authSlice';
import { useEffect, useRef } from 'react';
import img2 from '../../assets/img/img2.png';
import img3 from '../../assets/img/img3.png';
import Header from './Header';
import { cleanOrgDocObj, cleanOrgObj, clearObj } from '../../features/obj/objSlice';
import toast from 'react-hot-toast';
import RightSide from './RightSide';

export default function Login() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authLoading, isActive } = useSelector((state) => state.auth);

    const formRef = useRef(null);
    const onFinish = async (data) => {
        const res = await dispatch(loginIntial(data));
        if (res?.payload?.success) {
            toast.success(res?.payload?.messages?.message);
            await form.resetFields();
            await navigate('/login-otp');
        } else {
            toast.error(res?.payload?.messages?.message ?? 'Invalid Credentials');
        }
    };

    // useEffect(() => {
    //     if (!isActive) {
    //         dispatch(logout());
    //     }
    // }, []);

    // if (isActive) {
    //     return <Navigate to="/onboarding" replace />;
    // }

    const handleRegister = async () => {
        await dispatch(clearObj());
        await navigate('/registration');
    };
    async function clean() { 
        await dispatch(clearObj());
        await dispatch(clearAuthObj());
    }

    useEffect(() => {
        clean();
    }, []);

    return (
        <>
            <div className="flex w-full  min-h-screen bg-white ">
                <div className="flex flex-col w-full ">
                    <Header />
                    <div className="w-full h-full flex justify-center items-center lg:px-0 px-3">
                        <div className="flex flex-col w-full justify-center items-center text-center">
                            <span className="heading_1">Log in</span>
                            <span className="paragraph_1 mt-[1.13rem]">Welcome. Please fill in your details below.</span>

                            <Form
                                layout="vertical"
                                ref={formRef}
                                name="control-ref"
                                onFinish={onFinish}
                                style={{
                                    maxWidth: '100%',
                                    marginTop: '2.06rem',
                                    width: '32.25rem',
                                }}
                                form={form}
                            >
                                <Form.Item
                                    name="usrOrgCode"
                                    label="Organization Code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Organiation code is required to login',
                                        },
                                    ]}
                                >
                                    <Input className="input" />
                                </Form.Item>

                                <Form.Item
                                    name="userEmail"
                                    label="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Email is required to login',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please enter a valid email address',
                                        },
                                    ]}
                                >
                                    <Input type="email" className="input" />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your password',
                                        },
                                    ]}
                                >
                                    <Input.Password className="input" />
                                </Form.Item>

                                <div className="flex justify-end mt-[.5rem]">
                                    <Link to="/forgot-password" className="label_2">
                                        Forgot Password?
                                    </Link>
                                </div>

                                <div className="w-full flex flex-col items-center justify-center">
                                    <div className="flex justify-center w-[10.625rem] mt-[1.63rem]">
                                        <button className="cstm-btn-2" type="submit" disabled={authLoading ? true : false}>
                                            {authLoading ? <Spin /> : 'Get Code'}
                                        </button>
                                    </div>

                                    <div className="w-full justify-center flex mt-[1.19rem]">
                                        <span className="label_2 !text-[#0A0A0A]">Don’t have an account? </span>
                                        <button type="button" className=" label_2 !text-orangeYellows ml-[.1rem]" onClick={handleRegister}>
                                            Register
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

                <RightSide />
            </div>
        </>
    );
}
