import { Form, Input, Spin } from 'antd';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header';
import img2 from '../../../assets/img/img2.png';
import img3 from '../../../assets/img/img3.png';
import { resetPassword } from '../../../features/auth/authSlice';
import toast from 'react-hot-toast';
import RightSide from '../RightSide';

function ForgotPassword() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formRef = useRef(null);

    const { saving } = useSelector((state) => state.auth);

    const handleLogin = () => {
        navigate('/login');
    };

    const onFinish = async (data) => {
        const res = await dispatch(
            resetPassword({
                ...data,
                url: `auth/forgot-password`,
            })
        );
        if (res?.payload?.success) {
            toast.success(res.payload?.messages?.message);
            await navigate('/verify-email');
        } else {
            toast.error(res.payload?.messages?.message);
        }
    };

    return (
        <>
            <div className="flex w-full min-h-screen bg-white">
                <div className="flex flex-col w-full">
                    <Header />
                    <div className="w-full h-full flex justify-center items-center lg:px-0 px-3">
                        <div className="flex flex-col w-full justify-center items-center text-center">
                            <span className="heading_1">Reset Password</span>
                            <span className="paragraph_1 mt-[1.13rem]">If your email matches records on our system you will receive a notifcation on steps to reset your passsword</span>

                            <Form
                                layout="vertical"
                                ref={formRef}
                                name="control-ref"
                                onFinish={onFinish}
                                style={{
                                    maxWidth: '100%',
                                    marginTop: '2.06rem',
                                    width: '32.25rem',
                                }}
                                form={form}
                            >
                                <Form.Item
                                    name="usrOrgCode"
                                    label="Organization Code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Organiation code is required',
                                        },
                                    ]}
                                >
                                    <Input className="input" />
                                </Form.Item>

                                <Form.Item
                                    name="userEmail"
                                    label="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Email is required',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please enter a valid email address',
                                        },
                                    ]}
                                >
                                    <Input type="email" className="input" />
                                </Form.Item>

                                <div className="w-full flex flex-col items-center justify-center">
                                    <div className="flex justify-center w-[10.625rem] mt-[1.63rem]">
                                        <button className="cstm-btn-2" type="submit" disabled={saving ? true : false}>
                                            {saving ? <Spin /> : 'Submit'}
                                        </button>
                                    </div>

                                    <div className="w-[10.625rem] justify-center flex mt-[1.19rem]">
                                        <button type="button" className="cstm-btn" onClick={handleLogin}>
                                            Back to Login
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

                <RightSide/>
            </div>
        </>
    );
}

export default ForgotPassword;
